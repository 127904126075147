import React from 'react'
import { Component } from 'react'
class Header extends Component {
    constructor(props) {
        super(props);
        this.NavBack = this.NavBack.bind(this);
        this.state = {
            AnimationState: "mapme-fadeout", 
            page:'Splash'
        };
    }
    NavBack(){
        this.props.NavBack();
    }
    render() {
        return (
            <div className="mapme-header">
                <div className={this.state.AnimationState}><button type="button" className={`mapme-app-back-button mapme-mobile ${this.state.page === 'HealthyWeightInformation' ? 'mapme-fadeout':'mapme-fadein'}`} onClick={(e)=>this.NavBack(e)}>Back</button></div>
            </div>
        )
    }
}

export default Header;