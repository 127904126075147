import React from 'react'
import { Component } from 'react'
import parse from 'html-react-parser';

class Results extends Component {
    constructor(props) {
        super(props);
        this.checkEmail = this.checkEmail.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.setSelectedChild = this.setSelectedChild.bind(this);
        this.expand = this.expand.bind(this);
        this.swipeHandler = this.swipeHandler.bind(this);
        this.toggleExcuseMore = this.toggleExcuseMore.bind(this)
        this.ChildSelectStyle = {
            marginLeft: '-300%'
        };
        this.WeightCats = ["Underweight", "Healthy weight", "Healthy weight", "Healthy weight", "Overweight", "Very overweight", "Very overweight"]
        this.state = {
            AnimationState: "mapme-fadein-delayed",
            visibility: "visible",
            tabIndex: null,
            EmailDisabled: true,
            EmailStatus: false,
            EmailMessage: '',
            Email: '',
            ResultTitle: '',
            ResultAdviceTitle: '',
            ResultExplanation1: '',
            ResultExplanation2: '',
            ResultProjectionHead: '',
            ResultProjectionH3: '',
            ResultProjectionP: '',
            ResultExcusesHead: this.props.data.text_areas.result_excuses_head,
            ResultExcusesBody: this.props.data.text_areas.result_excuses_body,
            ResultInfoHead: this.props.data.text_areas.result_info_head,
            ResultInfoBody: this.props.data.text_areas.result_info_body,
            ResultEmailHead: this.props.data.text_areas.result_email_head,
            ResultEmailBody: this.props.data.text_areas.result_email_body,
            CWClabel: 1,
            AWClabel: 1,
            ChildChosenWeightCat: 4,
            ChildCompared: false,
            ChildActualWeightCat: 4,
            ChildMobileActualWeightCat: 4,
            ChildSelectedWeightCat: 4,
            ChildWeightCatLabel: 4,
            CompareLabel: '',
            Excuses: [],
            Info: [],
            labels: ['', '', '', '', '', '', ''],
            mobLabel1: '',
            mobLabel2: '',
            InfoClasses: ['start', 'middle', 'end'],
            resultStyle: 'none',
            resourcesVisited: []
        };
    }
    reset() {
        this.setState({
            ChildChosenWeightCat: 4,
            ChildCompared: false,
            ChildActualWeightCat: 4,
            ChildMobileActualWeightCat: 4,
            ChildSelectedWeightCat: 4,
            ChildWeightCatLabel: 4,
            Excuses: [],
            EmailDisabled: true,
            EmailStatus: false,
            EmailMessage: '',
            Email: '',
            Info: [],
            labels: ['', '', '', '', '', '', '']
        })
        this.forceUpdate()
    }
    swipeHandler(e) {
        if (e.event.target.className.indexOf('noswipe') === -1) {
            var val = 0
            var distX = Math.min(1, Math.abs(e.deltaX) / window.innerWidth) * Math.max(1, (e.velocity * 2))
            var distY = Math.abs(Math.min(1, Math.abs(e.deltaY) / window.innerHeight) * Math.max(1, (e.velocity * 2)))
            distX = Math.max(1, Math.ceil(distX))
            if (e.dir === 'Right') {
                val = 0 - distX
            } else {
                val = distX
            }
            val = Math.max(1, Math.min(7, this.state.ChildSelectedWeightCat + val))
            if (distY < 0.1) {
                this.ChildSelectStyle = {
                    marginLeft: (50 - ((val) * 50)) + '%'
                };
                this.setState({
                    ChildSelectedWeightCat: val,
                    ChildWeightCatLabel: val + 1,
                    ChildCompared: true
                });
            }
        }
    }
    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    checkEmail(event) {
        if (this.validateEmail(event.target.value)) {
            this.setState({ 'EmailDisabled': false, 'Email': event.target.value })
        } else {
            this.setState({ 'EmailDisabled': true, 'Email': event.target.value })
        }
    }
    sendEmail() {
        var url = 'https://mapmeuk.co.uk/API/sendmail.php'
        var xmlhttp = new XMLHttpRequest();
        var data = {};
        data['age'] = this.props.Form1.current.state.age
        data['sex'] = this.props.Form1.current.state.sex
        data['SessionID'] = this.props.SessionID
        data['Resulttitle'] = this.state.ResultTitle
        data['ResultProjectionH3'] = this.state.ResultProjectionH3
        data['ResultExplanation1'] = this.state.ResultExplanation1
        data['ResultExplanation2'] = this.state.ResultExplanation2
        data['ResultProjectionP'] = this.state.ResultProjectionP
        data['Info'] = this.state.Info
        data['Recipient'] = this.state.Email
        xmlhttp.open("POST", url, true);
        this.setState({ 'EmailStatus': true, 'EmailMessage': 'Sending email' });
        this.props.TrackPageHeight(900)
        xmlhttp.onreadystatechange = function (e) {
            if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
                var result = JSON.parse(e.target.response);
                if (result.status === 'success') {
                    this.setState({ 'EmailStatus': true, 'EmailMessage': 'Email sent' }, this.resetEmail());

                } else {
                    this.setState({ 'EmailStatus': false, 'EmailMessage': 'Email not sent (' + result.status + ')' });
                }
            }
        }.bind(this)
        xmlhttp.send(JSON.stringify(data));

    }
    resetEmail() {
        setTimeout(function () {
            this.setState({ 'EmailStatus': false, 'EmailMessage': '', 'EmailDisabled': true, Email: '' })
        }.bind(this), 2000)
    }
    replaceAll(str, mapObj) {
        var re = new RegExp(Object.keys(mapObj).join("|"), "gi");
        return str.replace(re, function (matched) {
            return mapObj[matched.toLowerCase()];
        });
    }
    catToClass(val) {
        return 'mapme-' + val.toLowerCase().split(' ').join('-');
    }
    externalLink(url) {
        var mapObj = { 'https://': '', 'http://': '', 'www.': '', 'nhs.uk': '' };
        this.state.resourcesVisited.push(this.replaceAll(url, mapObj))
        this.setState({ 'resourcesVisited': Array.from(new Set(this.state.resourcesVisited)) }, function () {
            this.props.antbitsLog(true, true)
        })
    }
    toggleExcuseMore(id) {
        var content = document.getElementById('mapme-excuse-content-' + id)
        var btn = document.getElementById('mapme-excuse-button-' + id)
        if (content.style.display === "none") {
            content.style.display = "block";
            btn.innerHTML = 'Read less'
        } else {
            content.style.display = "none";
            btn.innerHTML = 'Read more'
        }
        this.props.TrackPageHeight(300);
    }
    calcResult() {
        var rs = this.calcPercentile(this.props.EnterYourDetails.current.state.age, this.props.EnterYourDetails.current.state.kg, this.props.EnterYourDetails.current.state.cm, this.props.Form1.current.state.sex)
        var SelectedChild = this.props.SelectChild.current.state.ChildWeightCat;
        var l = ['', '', '', '', '', '', ''];
        var labelVals = [0, 1, 1, 1, 2, 3, 3];
        var p, excuses, info, CompareLabel
        var ResultAdviceTitle = this.props.data.text_areas.result_advice_title_incorrect
        if (rs.id < 3 && rs.id > 0) {
            ResultAdviceTitle = this.props.data.text_areas.result_advice_title_correct
        }
        if (SelectedChild === rs.id + 1) {
            l[rs.id] = 'Your choice';
            CompareLabel = rs.cat;
            p = this.props.data.results['Projection']['cat_' + (rs.id + 1)]

            this.setState({
                ResultTitle: 'Yes – you selected the right body shape for your child\'s weight',
                ResultAdviceTitle: ResultAdviceTitle,
                ChildCompared: true,
                ResultExplanation1: this.formatBody(this.props.data.results['Choice right 1']['cat_' + (rs.id + 1)], rs),
                ResultExplanation2: this.formatBody(this.props.data.results['Choice right 2']['cat_' + (rs.id + 1)], rs),
                Excuses: this.toArr(this.props.data.results['Excuses']['cat_' + (rs.id + 1)]),
                Info: this.toArr(this.props.data.results['Info ' + this.props.Form1.current.state.age]['cat_' + (rs.id + 1)])
            });
            this.ChildSelectStyle = {
                marginLeft: (0 - ((rs.id) * 50)) + '%'
            };
            this.setState({ 'resultStyle': 'none' });
        } else {
            CompareLabel = '<div class="' + this.catToClass(this.WeightCats[SelectedChild - 1]) + '">' + this.WeightCats[SelectedChild - 1] + '</div><div class="' + this.catToClass(rs.cat) + '">' + rs.cat + '</div>';
            l[SelectedChild - 1] = 'Your choice';
            l[rs.id] = 'Reality'
            if (SelectedChild > rs.id + 1) {
                excuses = this.toArr(this.props.data.results['Excuses']['cat_' + (rs.id + 1)])
                info = this.toArr(this.props.data.results['Info ' + this.props.Form1.current.state.age]['cat_' + (rs.id + 1)])
                p = this.props.data.results['Projection']['cat_' + (rs.id + 1)]
                this.setState({ 'resultStyle': 'left' });
            } else {
                excuses = this.toArr(this.props.data.results['Excuses']['cat_' + (rs.id + 1)])
                info = this.toArr(this.props.data.results['Info ' + this.props.Form1.current.state.age]['cat_' + (rs.id + 1)])
                p = this.props.data.results['Projection']['cat_' + (rs.id + 1)]
                this.setState({ 'resultStyle': 'none' });
            }
            this.ChildSelectStyle = {
                marginLeft: (50 - ((rs.id) * 50)) + '%'
            };
            this.setState({
                ResultTitle: 'Not quite – you selected a different body shape to your child’s weight',
                ResultAdviceTitle: ResultAdviceTitle,
                ResultExplanation1: this.formatBody(this.props.data.results['Choice wrong 1']['cat_' + (rs.id + 1)], rs),
                ResultExplanation2: this.formatBody(this.props.data.results['Choice wrong 2']['cat_' + (rs.id + 1)], rs),
                Excuses: excuses,
                Info: info
            }, function () {
                console.log(this.state)
            });
        }
        var ChildMobActual = rs.id + 1;
        if (ChildMobActual === SelectedChild && SelectedChild < 6) {
            ChildMobActual++;
        } else if (ChildMobActual === SelectedChild && SelectedChild > 0) {
            ChildMobActual--;
        }
        this.setState({
            ChildChosenWeightCat: SelectedChild,
            ChildSelectedWeightCat: rs.id + 1,
            ChildActualWeightCat: rs.id + 1,
            ChildMobileActualWeightCat: ChildMobActual,
            ChildWeightCatLabel: rs.id + 1,
            mobLabel1: 'Your choice',
            locked: false,
            mobLabel2: (rs.id + 1 === SelectedChild ? '' : 'Reality'),
            AWClabel: labelVals[SelectedChild - 1],
            CWClabel: labelVals[ChildMobActual - 1],
            CompareLabel: CompareLabel,
            ChildCompared: false,
            ResultProjectionH3: this.props.data.projection_assets[p]['h3'],
            ResultProjectionP: this.props.data.projection_assets[p]['p'],
            labels: l,
        }, function () {
            this.props.TrackPageHeight(900)
            this.props.antbitsLog(true, true, {
                "cen": rs.percentile,
                "a": this.props.EnterYourDetails.current.state.age,
                "wca": this.state.ChildActualWeightCat,
                "wcp": this.state.ChildChosenWeightCat,
                "wd": this.state.ChildActualWeightCat - this.state.ChildChosenWeightCat
            }, function () {
                console.log(this.state)
            })
        })
    }
    toArr(str) {
        if (str === '') {
            return []
        } else {
            return str.split(',')
        }
    }
    setSelectedChild(event) {
        var val = Math.max(1, Math.min(7, parseInt(event.target.value)))
        this.ChildSelectStyle = {
            marginLeft: (50 - ((val) * 50)) + '%'
        };
        this.setState({
            ChildSelectedWeightCat: val,
            ChildWeightCatLabel: val + 1,
            ChildCompared: true
        });
    }
    formatBody(str, rs) {
        var prefix = 'in the ';
        if (rs.percentile > 99) {
            prefix = 'above the '
        }
        if (rs.percentile < 1) {
            prefix = 'below the '
        }
        str = str.replace("[centile]", prefix + Math.floor(Math.max(1, Math.min(rs.percentile, 99))) + this.suffix(Math.floor(Math.max(1, rs.percentile))))
        str = str.replace("[weight]", "<strong>" + rs.cat.toLowerCase() + "</strong>")
        return str;
    }
    expand() {
        this.props.ExpandAdult.current.launch(parseInt(this.state.ChildActualWeightCat));
    }
    resetAnimation() {
        var elm = document.getElementById('mapme-preload-animation')
        var newone = elm.cloneNode(true);
        elm.parentNode.replaceChild(newone, elm);
    }
    suffix(num) {
        var tmp = num.toString().slice(-1)
        var output = "";
        switch (tmp) {
            case "1":
                output = "st";
                break;
            case "2":
                output = "nd";
                break;
            case "3":
                output = "rd";
                break;
            default:
                output = "th";
                break;
        }
        return output;
    }

    calcPercentile(a, w, h, g) {
        console.log('calc percentile')
        console.log(a+' '+w+' '+h+' '+g)
        var rs = {};
        var percentile = 0;
        var age_key = -1;
        var l = 0;
        var m = 0;
        var s = 0;
        var z = 0;
        var diff = 0;
        var weight_lookup = [{ id: 0, bracket: [0, 2], cat: "Underweight" }, { id: 1, bracket: [2, 50], cat: "Healthy weight" }, { id: 2, bracket: [50, 75], cat: "Healthy weight" }, { id: 3, bracket: [75, 91], cat: "Healthy weight" }, { id: 4, bracket: [91, 98], cat: "Overweight" }, { id: 5, bracket: [98, 99.6], cat: "Very overweight" }, { id: 6, bracket: [99.6, 1000], cat: "Very overweight" }]
        var age_lookup = [0, 0.083, 0.167, 0.25, 0.333, 0.417, 0.5, 0.583, 0.667, 0.75, 0.833, 0.917, 1, 1.083, 1.167, 1.25, 1.333, 1.417, 1.5, 1.583, 1.667, 1.75, 1.833, 1.917, 2, 2.083, 2.167, 2.25, 2.333, 2.417, 2.5, 2.583, 2.667, 2.75, 2.833, 2.917, 3, 3.083, 3.167, 3.25, 3.333, 3.417, 3.5, 3.583, 3.667, 3.75, 3.833, 3.917, 4, 4.083, 4.167, 4.25, 4.333, 4.417, 4.5, 4.583, 4.667, 4.75, 4.833, 4.917, 5, 5.083, 5.167, 5.25, 5.333, 5.417, 5.5, 5.583, 5.667, 5.75, 5.833, 5.917, 6, 6.083, 6.167, 6.25, 6.333, 6.417, 6.5, 6.583, 6.667, 6.75, 6.833, 6.917, 7, 7.083, 7.167, 7.25, 7.333, 7.417, 7.5, 7.583, 7.667, 7.75, 7.833, 7.917, 8, 8.083, 8.167, 8.25, 8.333, 8.417, 8.5, 8.583, 8.667, 8.75, 8.833, 8.917, 9, 9.083, 9.167, 9.25, 9.333, 9.417, 9.5, 9.583, 9.667, 9.75, 9.833, 9.917, 10, 10.083, 10.167, 10.25, 10.333, 10.417, 10.5, 10.583, 10.667, 10.75, 10.833, 10.917, 11, 11.083, 11.167, 11.25, 11.333, 11.417, 11.5, 11.583, 11.667, 11.75, 11.833, 11.917, 12, 12.083, 12.167, 12.25, 12.333, 12.417, 12.5, 12.583, 12.667, 12.75, 12.833, 12.917, 13, 13.083, 13.167, 13.25, 13.333, 13.417, 13.5, 13.583, 13.667, 13.75, 13.833, 13.917, 14, 14.083, 14.167, 14.25, 14.333, 14.417, 14.5, 14.583, 14.667, 14.75, 14.833, 14.917, 15, 15.083, 15.167, 15.25, 15.333, 15.417, 15.5, 15.583, 15.667, 15.75, 15.833, 15.917, 16, 16.083, 16.167, 16.25, 16.333, 16.417, 16.5, 16.583, 16.667, 16.75, 16.833, 16.917, 17, 17.083, 17.167, 17.25, 17.333, 17.417, 17.5, 17.583, 17.667, 17.75, 17.833, 17.917];
        var male_l = [-0.3053, 0.2708, 0.1118, 0.0068, -0.0727, -0.137, -0.1913, -0.2385, -0.2802, -0.3176, -0.3516, -0.3828, -0.4115, -0.4382, -0.463, -0.4863, -0.5082, -0.5289, -0.5484, -0.5669, -0.5846, -0.6014, -0.6174, -0.6328, -0.6187, -0.584, -0.5497, -0.5166, -0.485, -0.4552, -0.4274, -0.4016, -0.3782, -0.3572, -0.3388, -0.3231, -0.3101, -0.3, -0.2927, -0.2884, -0.2869, -0.2881, -0.2919, -0.2981, -0.3067, -0.3174, -0.3303, -0.3452, -1.291, -1.309, -1.325, -1.342, -1.359, -1.375, -1.391, -1.407, -1.422, -1.437, -1.452, -1.467, -1.481, -1.495, -1.509, -1.523, -1.536, -1.549, -1.562, -1.575, -1.587, -1.599, -1.611, -1.622, -1.634, -1.644, -1.655, -1.665, -1.675, -1.685, -1.694, -1.704, -1.713, -1.721, -1.73, -1.738, -1.745, -1.753, -1.76, -1.767, -1.774, -1.781, -1.787, -1.793, -1.798, -1.804, -1.809, -1.814, -1.818, -1.823, -1.827, -1.83, -1.834, -1.837, -1.84, -1.843, -1.846, -1.848, -1.85, -1.852, -1.854, -1.855, -1.856, -1.857, -1.858, -1.858, -1.859, -1.859, -1.859, -1.859, -1.859, -1.858, -1.857, -1.856, -1.855, -1.854, -1.853, -1.851, -1.85, -1.848, -1.846, -1.844, -1.842, -1.839, -1.837, -1.834, -1.831, -1.829, -1.826, -1.823, -1.819, -1.816, -1.813, -1.809, -1.806, -1.802, -1.799, -1.795, -1.791, -1.787, -1.783, -1.78, -1.776, -1.771, -1.767, -1.763, -1.759, -1.755, -1.75, -1.746, -1.742, -1.738, -1.733, -1.729, -1.724, -1.72, -1.715, -1.711, -1.707, -1.702, -1.697, -1.693, -1.689, -1.684, -1.68, -1.675, -1.671, -1.666, -1.661, -1.657, -1.652, -1.648, -1.643, -1.639, -1.635, -1.63, -1.626, -1.621, -1.617, -1.612, -1.608, -1.603, -1.599, -1.595, -1.59, -1.586, -1.582, -1.577, -1.573, -1.569, -1.564, -1.56, -1.556, -1.551, -1.547, -1.543, -1.538, -1.534, -1.53, -1.526, -1.521, -1.517, -1.513, -1.509, -1.505, -1.501, -1.496, -1.492, -1.492];
        var male_m = [13.4069, 14.9441, 16.3195, 16.8987, 17.1579, 17.2919, 17.3422, 17.3288, 17.2647, 17.1662, 17.0488, 16.9239, 16.7981, 16.6743, 16.5548, 16.4409, 16.3335, 16.2329, 16.1392, 16.0528, 15.9743, 15.9039, 15.8412, 15.7852, 16.0189, 15.98, 15.9414, 15.9036, 15.8667, 15.8306, 15.7953, 15.7606, 15.7267, 15.6934, 15.661, 15.6294, 15.5988, 15.5693, 15.541, 15.514, 15.4885, 15.4645, 15.442, 15.421, 15.4013, 15.3827, 15.3652, 15.3485, 15.752, 15.727, 15.704, 15.682, 15.662, 15.644, 15.626, 15.61, 15.595, 15.582, 15.569, 15.557, 15.547, 15.538, 15.53, 15.523, 15.517, 15.511, 15.507, 15.503, 15.5, 15.498, 15.497, 15.497, 15.498, 15.499, 15.501, 15.503, 15.507, 15.511, 15.516, 15.522, 15.529, 15.536, 15.545, 15.554, 15.564, 15.575, 15.587, 15.6, 15.614, 15.628, 15.643, 15.659, 15.675, 15.692, 15.71, 15.729, 15.748, 15.768, 15.789, 15.81, 15.833, 15.855, 15.88, 15.904, 15.929, 15.955, 15.982, 16.009, 16.037, 16.066, 16.095, 16.125, 16.155, 16.187, 16.219, 16.251, 16.284, 16.318, 16.352, 16.387, 16.423, 16.459, 16.496, 16.533, 16.57, 16.609, 16.648, 16.687, 16.727, 16.768, 16.808, 16.85, 16.892, 16.935, 16.977, 17.02, 17.065, 17.108, 17.154, 17.199, 17.244, 17.291, 17.338, 17.386, 17.433, 17.481, 17.53, 17.579, 17.629, 17.679, 17.729, 17.779, 17.83, 17.881, 17.933, 17.985, 18.037, 18.089, 18.142, 18.194, 18.247, 18.3, 18.354, 18.407, 18.46, 18.514, 18.567, 18.621, 18.675, 18.729, 18.783, 18.836, 18.89, 18.944, 18.997, 19.051, 19.104, 19.158, 19.211, 19.264, 19.317, 19.37, 19.423, 19.475, 19.528, 19.579, 19.632, 19.683, 19.735, 19.786, 19.837, 19.887, 19.938, 19.988, 20.038, 20.087, 20.137, 20.186, 20.234, 20.282, 20.33, 20.378, 20.425, 20.472, 20.519, 20.565, 20.611, 20.656, 20.702, 20.746, 20.791, 20.836, 20.879, 20.923, 20.967, 21.009, 21.009];
        var male_s = [0.0956, 0.09027, 0.08677, 0.08495, 0.08378, 0.08296, 0.08234, 0.08183, 0.0814, 0.08102, 0.08068, 0.08037, 0.08009, 0.07982, 0.07958, 0.07935, 0.07913, 0.07892, 0.07873, 0.07854, 0.07836, 0.07818, 0.07802, 0.07786, 0.07785, 0.07792, 0.078, 0.07808, 0.07818, 0.07829, 0.07841, 0.07854, 0.07867, 0.07882, 0.07897, 0.07914, 0.07931, 0.0795, 0.07969, 0.0799, 0.08012, 0.08036, 0.08061, 0.08087, 0.08115, 0.08144, 0.08174, 0.08205, 0.07684, 0.07687, 0.07692, 0.077, 0.07709, 0.0772, 0.07733, 0.07748, 0.07765, 0.07784, 0.07806, 0.07829, 0.07856, 0.07884, 0.07915, 0.07948, 0.07983, 0.0802, 0.08059, 0.081, 0.08143, 0.08189, 0.08235, 0.08284, 0.08334, 0.08386, 0.08439, 0.08494, 0.08549, 0.08606, 0.08663, 0.08722, 0.08781, 0.08841, 0.08901, 0.08962, 0.09023, 0.09084, 0.09145, 0.09207, 0.09268, 0.0933, 0.09391, 0.09451, 0.09512, 0.09572, 0.09632, 0.09691, 0.09749, 0.09807, 0.09864, 0.0992, 0.09976, 0.1003, 0.10084, 0.10137, 0.10189, 0.1024, 0.1029, 0.1034, 0.10388, 0.10435, 0.10482, 0.10527, 0.10571, 0.10615, 0.10657, 0.10698, 0.10738, 0.10777, 0.10815, 0.10852, 0.10888, 0.10923, 0.10957, 0.1099, 0.11022, 0.11054, 0.11084, 0.11114, 0.11143, 0.1117, 0.11197, 0.11223, 0.11249, 0.11273, 0.11296, 0.11319, 0.11341, 0.11362, 0.11382, 0.11402, 0.1142, 0.11438, 0.11456, 0.11472, 0.11488, 0.11503, 0.11517, 0.11532, 0.11545, 0.11558, 0.1157, 0.11581, 0.11592, 0.11603, 0.11613, 0.11622, 0.11631, 0.11639, 0.11647, 0.11655, 0.11662, 0.11668, 0.11674, 0.1168, 0.11685, 0.1169, 0.11695, 0.11699, 0.11703, 0.11706, 0.1171, 0.11712, 0.11715, 0.11717, 0.11719, 0.11721, 0.11722, 0.11723, 0.11724, 0.11724, 0.11725, 0.11725, 0.11725, 0.11724, 0.11724, 0.11723, 0.11722, 0.11721, 0.11719, 0.11718, 0.11716, 0.11714, 0.11712, 0.1171, 0.11708, 0.11706, 0.11703, 0.117, 0.11698, 0.11695, 0.11692, 0.11689, 0.11686, 0.11683, 0.1168, 0.11677, 0.11674, 0.1167, 0.11667, 0.11663, 0.1166, 0.11657, 0.11653, 0.11649, 0.11646, 0.11642, 0.11642];
        var female_l = [-0.0631, 0.3448, 0.1749, 0.0643, -0.0191, -0.0864, -0.1429, -0.1916, -0.2344, -0.2725, -0.3068, -0.3381, -0.3667, -0.3932, -0.4177, -0.4407, -0.4623, -0.4825, -0.5017, -0.5199, -0.5372, -0.5537, -0.5695, -0.5846, -0.5684, -0.5684, -0.5684, -0.5684, -0.5684, -0.5684, -0.5684, -0.5684, -0.5684, -0.5684, -0.5684, -0.5684, -0.5684, -0.5684, -0.5684, -0.5684, -0.5684, -0.5684, -0.5684, -0.5684, -0.5684, -0.5684, -0.5684, -0.5684, -1.151, -1.157, -1.163, -1.169, -1.175, -1.181, -1.187, -1.193, -1.198, -1.204, -1.209, -1.215, -1.22, -1.225, -1.231, -1.236, -1.241, -1.245, -1.25, -1.255, -1.26, -1.264, -1.269, -1.273, -1.277, -1.281, -1.286, -1.289, -1.293, -1.297, -1.301, -1.304, -1.308, -1.311, -1.314, -1.317, -1.32, -1.323, -1.325, -1.328, -1.33, -1.332, -1.334, -1.336, -1.338, -1.339, -1.341, -1.342, -1.344, -1.345, -1.345, -1.346, -1.347, -1.347, -1.348, -1.348, -1.348, -1.349, -1.349, -1.348, -1.348, -1.348, -1.347, -1.347, -1.346, -1.346, -1.345, -1.344, -1.343, -1.342, -1.341, -1.34, -1.339, -1.338, -1.337, -1.336, -1.334, -1.333, -1.332, -1.33, -1.329, -1.327, -1.326, -1.324, -1.322, -1.321, -1.319, -1.318, -1.316, -1.314, -1.312, -1.311, -1.309, -1.307, -1.306, -1.304, -1.302, -1.3, -1.299, -1.297, -1.295, -1.293, -1.291, -1.29, -1.288, -1.286, -1.284, -1.283, -1.281, -1.279, -1.277, -1.276, -1.274, -1.272, -1.271, -1.269, -1.267, -1.266, -1.264, -1.262, -1.261, -1.259, -1.258, -1.256, -1.254, -1.253, -1.251, -1.25, -1.248, -1.247, -1.245, -1.244, -1.242, -1.241, -1.239, -1.238, -1.236, -1.235, -1.233, -1.232, -1.231, -1.229, -1.228, -1.226, -1.225, -1.224, -1.222, -1.221, -1.22, -1.218, -1.217, -1.216, -1.214, -1.213, -1.212, -1.21, -1.209, -1.208, -1.206, -1.205, -1.204, -1.203, -1.201, -1.2, -1.199, -1.197, -1.196, -1.195, -1.195];
        var female_m = [13.3363, 14.5679, 15.7679, 16.3574, 16.6703, 16.8386, 16.9083, 16.902, 16.8404, 16.7406, 16.6184, 16.4875, 16.3568, 16.2311, 16.1128, 16.0028, 15.9017, 15.8096, 15.7263, 15.6517, 15.5855, 15.5278, 15.4787, 15.438, 15.6881, 15.659, 15.6308, 15.6037, 15.5777, 15.5523, 15.5276, 15.5034, 15.4798, 15.4572, 15.4356, 15.4155, 15.3968, 15.3796, 15.3638, 15.3493, 15.3358, 15.3233, 15.3116, 15.3007, 15.2905, 15.2814, 15.2732, 15.2661, 15.656, 15.639, 15.622, 15.605, 15.589, 15.573, 15.557, 15.542, 15.528, 15.515, 15.503, 15.492, 15.483, 15.475, 15.468, 15.463, 15.46, 15.457, 15.457, 15.458, 15.461, 15.465, 15.47, 15.477, 15.485, 15.494, 15.506, 15.517, 15.53, 15.544, 15.56, 15.577, 15.596, 15.614, 15.635, 15.656, 15.677, 15.7, 15.723, 15.748, 15.772, 15.798, 15.824, 15.85, 15.877, 15.905, 15.934, 15.963, 15.993, 16.022, 16.054, 16.085, 16.118, 16.15, 16.184, 16.218, 16.253, 16.288, 16.324, 16.361, 16.399, 16.437, 16.475, 16.515, 16.555, 16.596, 16.637, 16.679, 16.722, 16.765, 16.809, 16.853, 16.898, 16.943, 16.99, 17.036, 17.083, 17.131, 17.179, 17.227, 17.277, 17.327, 17.377, 17.427, 17.478, 17.53, 17.581, 17.634, 17.687, 17.739, 17.793, 17.846, 17.9, 17.954, 18.008, 18.062, 18.117, 18.172, 18.227, 18.281, 18.337, 18.391, 18.446, 18.5, 18.555, 18.61, 18.664, 18.718, 18.772, 18.825, 18.88, 18.932, 18.985, 19.038, 19.09, 19.142, 19.194, 19.244, 19.295, 19.345, 19.395, 19.445, 19.493, 19.542, 19.589, 19.637, 19.684, 19.73, 19.776, 19.822, 19.866, 19.911, 19.955, 19.998, 20.041, 20.083, 20.124, 20.166, 20.206, 20.246, 20.285, 20.324, 20.363, 20.401, 20.438, 20.475, 20.511, 20.547, 20.582, 20.617, 20.652, 20.685, 20.718, 20.751, 20.783, 20.816, 20.847, 20.878, 20.908, 20.938, 20.968, 20.997, 21.026, 21.054, 21.082, 21.11, 21.137, 21.164, 21.164];
        var female_s = [0.09272, 0.09556, 0.09371, 0.09254, 0.09166, 0.09096, 0.09036, 0.08984, 0.08939, 0.08898, 0.08861, 0.08828, 0.08797, 0.08768, 0.08741, 0.08716, 0.08693, 0.08671, 0.0865, 0.0863, 0.08612, 0.08594, 0.08577, 0.0856, 0.08454, 0.08452, 0.08449, 0.08446, 0.08444, 0.08443, 0.08444, 0.08448, 0.08455, 0.08467, 0.08484, 0.08506, 0.08535, 0.08569, 0.08609, 0.08654, 0.08704, 0.08757, 0.08813, 0.08872, 0.08931, 0.08991, 0.09051, 0.0911, 0.08728, 0.0877, 0.08814, 0.0886, 0.08906, 0.08954, 0.09004, 0.09054, 0.09106, 0.0916, 0.09214, 0.0927, 0.09326, 0.09384, 0.09443, 0.09503, 0.09563, 0.09624, 0.09686, 0.09749, 0.09812, 0.09875, 0.0994, 0.10004, 0.10069, 0.10135, 0.102, 0.10266, 0.10332, 0.10397, 0.10463, 0.10529, 0.10595, 0.1066, 0.10725, 0.10789, 0.10854, 0.10918, 0.10981, 0.11044, 0.11106, 0.11167, 0.11228, 0.11288, 0.11346, 0.11404, 0.11461, 0.11517, 0.11572, 0.11625, 0.11679, 0.1173, 0.1178, 0.1183, 0.11879, 0.11926, 0.11972, 0.12017, 0.1206, 0.12103, 0.12144, 0.12185, 0.12223, 0.12261, 0.12298, 0.12333, 0.12367, 0.124, 0.12432, 0.12462, 0.12492, 0.1252, 0.12547, 0.12573, 0.12598, 0.12622, 0.12644, 0.12666, 0.12687, 0.12706, 0.12725, 0.12742, 0.12759, 0.12774, 0.12789, 0.12803, 0.12816, 0.12827, 0.12838, 0.12849, 0.12858, 0.12866, 0.12875, 0.12882, 0.12888, 0.12894, 0.12899, 0.12903, 0.12907, 0.1291, 0.12913, 0.12915, 0.12917, 0.12918, 0.12918, 0.12919, 0.12919, 0.12918, 0.12917, 0.12916, 0.12914, 0.12913, 0.1291, 0.12908, 0.12905, 0.12902, 0.12899, 0.12895, 0.12892, 0.12888, 0.12884, 0.12879, 0.12875, 0.1287, 0.12866, 0.12861, 0.12856, 0.12851, 0.12846, 0.1284, 0.12835, 0.1283, 0.12824, 0.12819, 0.12813, 0.12807, 0.12802, 0.12796, 0.1279, 0.12785, 0.12779, 0.12773, 0.12768, 0.12762, 0.12757, 0.12751, 0.12745, 0.1274, 0.12734, 0.12729, 0.12723, 0.12718, 0.12712, 0.12707, 0.12702, 0.12696, 0.12691, 0.12686, 0.12681, 0.12676, 0.12671, 0.12666, 0.1266, 0.12656, 0.1265, 0.12646, 0.12641, 0.12636, 0.12636];
        var bmi = w / (Math.pow((h / 100), 2));
        
        for(var i in age_lookup){
			if(age_lookup[i] > a){
                    age_key = parseInt(i)
                    console.log(a+' - '+age_lookup[i-1]+' / '+age_lookup[i]+' - '+age_lookup[i-1])
                    diff = (a - age_lookup[i-1])/(age_lookup[i]-age_lookup[i-1])

				break;
			}
		}
        if (g === 'M') {
            l = interpolate(male_l[age_key-1], male_l[age_key], diff)
            m = interpolate(male_m[age_key-1], male_m[age_key], diff)
            s = interpolate(male_s[age_key-1], male_s[age_key], diff)
        } else {
            l = interpolate(female_l[age_key-1], female_l[age_key], diff)
            m = interpolate(female_m[age_key-1], female_m[age_key], diff)
            s = interpolate(female_s[age_key-1], female_s[age_key], diff)
        }
        z = (Math.pow((bmi / m), l) - 1) / (l * s);
        percentile = Math.floor(normdist(z, 0, 1) * 1000) / 10;
        console.log(percentile)
        for (i in weight_lookup) {
            if (percentile >= weight_lookup[i]['bracket'][0] && percentile < weight_lookup[i]['bracket'][1]) {
                rs = weight_lookup[i]
                break;
            }
        }
        rs['percentile'] = percentile;
        rs['percentile_str'] = Math.round(percentile) + suffix(Math.round(percentile))
        return rs;
        function suffix(num) {
            var tmp = num.toString().slice(-1)
            var output = "th"
            switch (tmp) {
                case "1":
                    output = "st";
                    break;
                case "2":
                    output = "nd";
                    break;
                case "3":
                    output = "rd";
                    break;
            }
            return output;
        }
        function interpolate(a, b, c) {
            console.log('interpolate '+a+' '+b+' '+c)
            return a + ((b - a) * c)
        }
        function normdist(z, mean, sigma) {
            var res = 0;
            z = (z - mean) / sigma;
            if (z === 0) {
                res = 0.5;
            } else {
                var oor2pi = 1 / Math.sqrt(2 * 3.14159265358979323846);
                var t = 1 / (1 + 0.2316419 * Math.abs(z));
                t = t * oor2pi * Math.exp(-0.5 * z * z) * (0.31938153 + t * (-0.356563782 + t * (1.781477937 + t * (-1.821255978 + t * 1.330274429))));
                if (z >= 0) {
                    res = 1 - t;
                } else {
                    res = t;
                }
            }
            if (z < (-4)) {
                res = 0;
            }
            if (z > 4) {
                res = 1;
            }
            return res;
        }
    }
    render() {
        return (
            <div className={`mapme-page ${this.state.AnimationState}`} style={{ visibility: this.state.visibility }} id="mapme-Results">
                <button type="button" tabIndex={this.state.locked ? -1 : null} className="mapme-app-back-button  mapme-desktop" onClick={() => this.props.navigate("Confirmation", true)}>Back</button>
                <h2>{this.state.ResultTitle}</h2>

                <div className="mapme-desktop mapme-result-child-compare"><div>
                    {
                        Array.from({ length: 7 }, (_, k) => (
                            <div key={k} className={` mapme-child-result ${this.state.ChildActualWeightCat === (k + 1) ? 'mapme-result-actual' : ''} ${this.state.ChildChosenWeightCat === (k + 1) ? 'mapme-result-selected' : ''}`}>
                                <div className="mapme-result-text-label">{this.state.labels[k]}</div>
                                <div className="mapme-result-image" aria-describedby={k + 1} htmlFor={'child-' + (k + 1)}>
                                    <img src={require('../' + this.props.getFileName(k + 1, 0)).default} draggable="false" alt={"Child of weight category " + (k + 1)} />
                                </div>
                                <div className="mapme-result-number-label">{k + 1}</div>
                            </div>
                        ))
                    }
                </div>
                    <div id="mapme-result-categories"><div>Underweight</div><div>Healthy weight</div><div>Overweight</div><div>Very overweight</div></div>
                </div>
                <div className="mapme-mobile mapme-result-child-compare">
                    <div key={'ChildChosen' + this.state.ChildChosenWeightCat} className="mapme-child-result">
                        <div className={`mapme-result-image mapme-result-child-compare-highlight`}>
                            <span>{this.state.mobLabel1}</span>
                            <img src={require('../' + this.props.getFileName(this.state.ChildChosenWeightCat, 0)).default} draggable="false" alt={"Child of weight category " + (this.state.ChildChosenWeightCat)} />
                        </div>
                    </div>
                    <div key={'ChildActual' + this.state.ChildMobileActualWeightCat} className="mapme-child-result" style={{ float: this.state.resultStyle }}>
                        <div className={`mapme-result-image`}>
                            <span style={{ display: this.state.mobLabel2 === '' ? 'none' : '' }} ><span>{this.state.mobLabel2}</span></span>
                            <img src={require('../' + this.props.getFileName(this.state.ChildMobileActualWeightCat, 0)).default} draggable="false" alt={"Child of weight category " + (this.state.ChildMobileActualWeightCat)} />
                        </div>
                    </div>
                </div>
                <div className="mapme-mobile mapme-result-child-compare-legend">
                    <div className="mapme-result-child-compare-categories">
                        <ul>
                            <li style={{ display: this.state.CWClabel === 0 && this.state.AWClabel === 0 ? 'inline-block' : 'none' }} className="mapme-underweight mapme-label-wide">Underweight</li>
                            <li style={{ display: this.state.CWClabel === 1 && this.state.AWClabel === 1 ? 'inline-block' : 'none' }} className="mapme-healthy-weight mapme-label-wide">Healthy weight</li>
                            <li style={{ display: this.state.CWClabel === 2 && this.state.AWClabel === 2 ? 'inline-block' : 'none' }} className="mapme-overweight mapme-label-wide">Overweight</li>
                            <li style={{ display: this.state.CWClabel === 3 && this.state.AWClabel === 3 ? 'inline-block' : 'none' }} className="mapme-very-overweight mapme-label-wide">Very overweight</li>

                            <li style={{ display: this.state.CWClabel !== 0 && this.state.AWClabel === 0 ? 'inline-block' : 'none' }} className="mapme-underweight">Underweight</li>
                            <li style={{ display: this.state.CWClabel !== 1 && this.state.AWClabel === 1 ? 'inline-block' : 'none' }} className="mapme-healthy-weight">Healthy weight</li>
                            <li style={{ display: this.state.CWClabel !== 2 && this.state.AWClabel === 2 ? 'inline-block' : 'none' }} className="mapme-overweight">Overweight</li>
                            <li style={{ display: this.state.CWClabel !== 3 && this.state.AWClabel === 3 ? 'inline-block' : 'none' }} className="mapme-very-overweight">Very overweight</li>

                            <li style={{ float: this.state.resultStyle, display: this.state.CWClabel === 0 && this.state.AWClabel !== 0 ? 'inline-block' : 'none' }} className="mapme-underweight">Underweight</li>
                            <li style={{ float: this.state.resultStyle, display: this.state.CWClabel === 1 && this.state.AWClabel !== 1 ? 'inline-block' : 'none' }} className="mapme-healthy-weight">Healthy weight</li>
                            <li style={{ float: this.state.resultStyle, display: this.state.CWClabel === 2 && this.state.AWClabel !== 2 ? 'inline-block' : 'none' }} className="mapme-overweight">Overweight</li>
                            <li style={{ float: this.state.resultStyle, display: this.state.CWClabel === 3 && this.state.AWClabel !== 3 ? 'inline-block' : 'none' }} className="mapme-very-overweight">Very overweight</li>

                            <li className="mapme-divider" style={{ display: this.state.CWClabel !== this.state.AWClabel ? 'inline-block' : 'none' }}></li>
                        </ul>
                    </div>
                    <div className="mapme-result-child-compare-numbers">
                        {
                            Array.from({ length: 7 }, (_, k) => (
                                <div style={{ left: ((k + 0.5) * 14.28) + '%' }} key={k} className={((this.state.ChildChosenWeightCat - 1) === k ? 'mapme-chosen' : '') + ' ' + ((this.state.ChildActualWeightCat - 1) === k ? 'mapme-actual' : '')}>
                                    {k + 1}
                                </div>
                            ))
                        }
                    </div>
                </div>
                {parse(this.state.ResultExplanation1)}
                {parse(this.state.ResultExplanation2)}
                <div>
                    <hr />
                    <h2> {parse(this.state.ResultAdviceTitle)}</h2>
                    <div id="mapme-projection">
                        <div >
                            {parse(this.state.ResultProjectionH3)}
                            {parse(this.state.ResultProjectionP)}
                        </div>
                        <div>
                            <img src={require('../' + this.props.getFileName(this.state.ChildActualWeightCat, 32)).default} alt={"Child of weight category " + (this.state.ChildActualWeightCat)} />
                            <button onClick={() => this.expand()} tabIndex={this.state.locked ? -1 : null}>
                                <img src={require('../' + this.props.getAdultImg(this.state.ChildActualWeightCat, 32, true)).default} alt={"Adult of weight category " + (this.state.ChildActualWeightCat)} />
                                <div><span className="mapme-desktop">Click to enlarge</span><span className="mapme-mobile">Tap to enlarge</span></div>
                            </button>
                        </div>
                    </div>
                    <hr className={this.state.Excuses.length === 0 ? 'mapme-hidden' : ''} />
                    <div id="mapme-excuses" className={this.state.Excuses.length === 0 ? 'mapme-hidden' : ''}>
                        <h2>{this.state.ResultExcusesHead}</h2>
                        {this.state.ResultExcusesBody}
                        <div>
                            {this.state.Excuses.map((value, index) => {
                                var node = this.props.data.excuses_assets[value]
                                return <details key={index} tabIndex={this.state.locked ? -1 : null}><summary onClick={() => this.props.TrackPageHeight(300)}>{parse(node.body)}</summary><div>{parse(node.truth)}<button className="mapme-excuse-button" id={'mapme-excuse-button-' + index} onClick={() => this.toggleExcuseMore(index)}>Read more</button><div style={{ display: 'none' }} id={'mapme-excuse-content-' + index}>{parse(node.read_more)}</div></div></details>
                            })}
                        </div>
                    </div>
                    <hr className={this.state.Info.length === 0 ? 'mapme-hidden' : ''} />
                    <div id="mapme-info" className={this.state.Info.length === 0 ? 'mapme-hidden' : ''}>
                        <h2>{this.state.ResultInfoHead}</h2>
                        {this.state.ResultInfoBody}

                        <div>
                            {this.state.Info.map((value, index) => {
                                var node = this.props.data.result_assets[value]
                                var c = 0;
                                if (typeof node !== 'undefined') {
                                    if (node.image !== '') {
                                        var img = '../assets/results/' + node.image;
                                        return <div key={index} className={`mapme-info-${this.state.InfoClasses[c % 3]}`}><a href={node.url} target="_blank" onClick={() => this.externalLink(node.url)} tabIndex={this.state.locked ? -1 : null}><img alt={node.header} src={require('../assets/results/' + node.image).default} /><h3>{node.header}</h3></a><div>{parse(node.copy)}</div></div>
                                        c++;
                                    }
                                }
                            })}
                        </div>
                    </div>
                    <hr />
                    <div id="mapme-email" role="group">
                        <legend >
                            <h2>{this.state.ResultEmailHead}</h2>
                            <p>{this.state.ResultEmailBody}</p>
                        </legend>
                        <label htmlFor="mapme-email">Email address</label>
                        <input value={this.state.Email} tabIndex={this.state.locked ? -1 : null} onChange={this.checkEmail} id="mapme-email" type="text" name="mapme-email" ></input>
                        <div className={`mapme-input-status ${this.state.EmailMessage === '' ? 'mapme-fadeout' : 'mapme-fadein'} ${this.state.EmailStatus ? 'mapme-valid' : 'mapme-complete'} `}>
                            {this.state.EmailMessage}
                        </div>
                    </div>
                </div>
                <button type="button" tabIndex={this.state.locked ? -1 : null} id="mapme-send-btn" disabled={this.state.EmailDisabled} className="mapme-email  mapme-app-button" onClick={() => this.sendEmail()}>Send</button>
                <div className="mapme-center"><button type="button" tabIndex={this.state.locked ? -1 : null} id="mapme-reset-btn" className="mapme-app-reset" onClick={() => this.props.reset()}>Reset tool and start again</button></div>
            </div>
        )
    }
}

export default Results;