import React from 'react'
import { Component } from 'react'
import parse from 'html-react-parser';
class MapMeID extends Component {
    constructor(props) {
        super(props);
        this.chars = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        this.displayVals = { "M": "male", "F": "female", "REC": "4 to 5", "YR6": "10 to 11" }
        this.reflength = 5;
        this.handleChange = this.handleChange.bind(this);
        this.validMessage = "";
        this.state = {
            AnimationState: "mapme-fadeout",
            visibility: "hidden",
            disabled: true,
            referenceSex: "",
            referenceAge: "",
            MapMeID: "",
            referenceComplete: false,
            valid: false


        };
    }
    reset() {
        this.setState({
            AnimationState: "mapme-fadeout",
            visibility: "hidden",
            referenceSex: "",
            referenceAge: "",
            MapMeID: "",
            disabled: true,
            referenceComplete: false,
            valid: false
        })
    }
    testData() {
        this.setState({
            referenceSex: "M",
            referenceAge: "R",
            MapMeID: "114D"
        })
    }

    decrypt = function (input) {
        var output = { 'status': 'error' }
        var outputVals = { 'R': 'REC', '6': 'YR6', }
        output['sex'] = this.state.referenceSex
        output['age'] = outputVals[this.state.referenceAge]
        var num = input
        var b = 33;
        var base = '0123456789ABCDFGHJKLMNPQRSTUVWXYZ';
        var limit = num.length;
        var res = base.indexOf(num[0])
        for (var i = 1; i < limit; i++) {
            res = b * res + base.indexOf(num[i]);
        }
        output['status'] = 'success'
        output['ref'] = res - 35937;
        return output;
    }
    checkForm = () => {
        JSON.parse('{"status":1}')
        if (this.state.MapMeID.length === 4 && this.state.referenceSex.length === 1 && this.state.referenceAge.length === 1) {
          
            this.result = this.decrypt(this.state.MapMeID)
            var url = 'https://mapmeuk.co.uk/API/lookup.php?code=' + this.state.MapMeID
            var xmlhttp = new XMLHttpRequest();
            xmlhttp.open("GET", url, true);
            xmlhttp.onreadystatechange = function (e) {
                if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
                    var result = JSON.parse(e.target.response);
                    this.setState({ referenceComplete: true })
                    if (result.status === 1) {
                        this.validMessage = "Your child is in the " + this.displayVals[this.result.age] + " age group and is " + this.displayVals[this.result.sex];
                        this.setState({ valid: true, disabled: false })
                        this.props.Form1.current.setState({ sex: this.result.sex, age: this.result.age })
                        this.props.SelectChild.current.setState({ title: this.props.data.text_areas['sc-title-' + this.result.sex + '-' + this.result.age] })
                        setTimeout(function(){document.getElementById('MapMeIDContinue').focus()},900)
                    } else {
                        this.setState({ valid: false, disabled: true })
                        this.props.Form1.current.setState({ sex: null, age: null })
                        this.props.SelectChild.current.setState({ title: '' })
                    }
                }
            }.bind(this);
            xmlhttp.send();
        } else {
            this.setState({ referenceComplete: false, valid: false, disabled: true })
            this.props.Form1.current.setState({ sex: null, age: null })
        }
        this.props.TrackPageHeight(300);
    };
    handleChange(event) {
        switch (event.target.id) {
            case 'mapme-referenceSex':
                this.setState({
                    "referenceSex": event.target.value.replace(/[^mfMF+]/g, '').slice(0, 1).toUpperCase()
                }, function () {
                    this.checkForm();
                    if (this.state.referenceSex !== '') {
                        document.getElementById('mapme-referenceAge').focus();
                    }
                });
                break;
            case 'mapme-referenceAge':
                this.setState({
                    "referenceAge": event.target.value.replace(/[^r6R+]/g, '').slice(0, 1).toUpperCase()
                }, function () {
                    this.checkForm();
                });
                break;
            default:
                this.setState({
                    "MapMeID": event.target.value.replace(/\s+/g, '').slice(0, 4).toUpperCase()
                }, function () {
                    this.checkForm();
                    if (this.state.MapMeID.length === 4) {
                        document.getElementById('mapme-referenceSex').focus();
                    }
                });
                break;
        }


    }
    clearInput() {
        this.setState({ "MapMeID": "", "referenceAge": "", "referenceSex": "" }, this.checkForm);
    }
    render() {
        return (
            <div className={`mapme-page ${this.state.AnimationState}`} style={{ visibility: this.state.visibility }} id="mapme-MapMeID">
                <button type="button" className="mapme-app-back-button  mapme-desktop" onClick={() => this.props.navigate("Start", true)}>Back</button>
                <div className="mapme-form-panel">
                    <fieldset >
                        <legend>
                            <h2>{this.props.data.text_areas.reference_title}</h2>
                            <div>{parse(this.props.data.text_areas.reference_body)}</div>
                            <details><summary onClick={() => this.props.TrackPageHeight(300)}><div>Why do you need this?</div></summary><span>{parse(this.props.data.text_areas.reference_why)}</span></details>
                        </legend>
                        <div className="mapme-stacked-group">
                            <div className={`mapme-inline`}>
                                <label className="mapme-label" htmlFor="mapme-referenceNumberA">Enter MapMe ID</label></div>
                            <div className={`mapme-inline`}>
                                <input autoComplete="off" className={`mapme-input--width-4`} placeholder = "6DK3" onChange={this.handleChange} id="mapme-MapMeID" type="text" maxLength="4" value={this.state.MapMeID} name="mapme-MapMeID"></input>
                                <input autoComplete="off" className={`mapme-input--width-1`} placeholder="M" onChange={this.handleChange} id="mapme-referenceSex" type="text" maxLength="1" value={this.state.referenceSex} name="mapme-referenceSex"></input>
                                <input autoComplete="off" className={`mapme-input--width-1`} placeholder = "6" onChange={this.handleChange} id="mapme-referenceAge" type="text" maxLength="1" value={this.state.referenceAge} name="mapme-referenceAge"></input>
                                <button tabIndex={this.state.referenceNumberDisplay === '' ? -1 : null} className={`mapme-clear ${(this.state.MapMeID.length > 0 ? 'mapme-fadein' : 'mapme-fadeout')}`} onClick={() => this.clearInput()}></button></div>
                        </div>

                        <div className={`mapme-input-status ${this.state.valid ? 'mapme-valid' : ''} ${this.state.referenceComplete ? 'mapme-complete' : ''}`}>
                            {this.state.referenceComplete ? this.state.valid ? this.validMessage : this.props.data.text_areas.reference_invalid : ''}
                        </div>
                    </fieldset >
                </div>
                <button id="MapMeIDContinue" type="button" className="mapme-app-button" disabled={this.state.disabled} onClick={() => this.props.navigate("SelectChild", false)}>Continue</button>
            </div>
        )
    }
}
export default MapMeID;