/* eslint-disable default-case */
import React from 'react'
import { Component } from 'react'
import parse from 'html-react-parser';

class EnterYourDetails extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.toggleDom = this.toggleDom.bind(this);
        this.checkDOB = this.checkDOB.bind(this);
        this.checkDOM = this.checkDOM.bind(this);
        this.checkNum = this.checkNum.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
        this.checkUnits = this.checkUnits.bind(this);
        this.toggleUnits = this.toggleUnits.bind(this);
        this.errors = {}
        this.warnings = { 'dob': false }
        this.dateDelay = null;
        this.now = new Date();
        this.submitted = false;
        this.state = {
            AnimationState: "mapme-fadeout",
            visibility: "hidden",
            disabled: true,
            ChildWeightCat: '4',
            heightUnit: 'metric',
            weightUnit: 'metric',
            cm: '',
            kg: '',
            age: 0,
            feet: '',
            stone: '',
            inches: '',
            pounds: '',
            dobYear: '',
            dobMonth: '',
            dobDay: '',
            domYear: '',
            domMonth: '',
            domDay: '',
            domState: 'mapme-slideup',
            DOB: null,
            DOM: null
        };

    }
    reset() {
        this.setState({
            AnimationState: "mapme-fadeout",
            visibility: "hidden",
            disabled: true,
            ChildWeightCat: '4',
            heightUnit: 'metric',
            weightUnit: 'metric',
            cm: '',
            kg: '',
            age: 0,
            feet: '',
            stone: '',
            inches: '',
            pounds: '',
            dobYear: '',
            dobMonth: '',
            dobDay: '',
            domYear: '',
            domMonth: '',
            domDay: '',
            domState: 'mapme-slideup',
            DOB: null,
            DOM: null
        })
    }
    testData() {
        this.setState({
            age: 40,
            cm: 140,
            kg: 60,
            feet: 4,
            inches: 7,
            stone: 9,
            pounds: 6,
            dobYear: 2008,
            dobMonth: 1,
            dobDay: 1,
            domState: 'mapme-slideup',
            DOB: new Date()
        }, function () {
            //this.setAge()
            //this.checkForm()
        })

    }
    toggleUnits(unit, val) {
        this.setState({ [unit + 'Unit']: val });
        //console.log(unit+' '+val)
        setTimeout(function () {
            switch (unit + ' ' + val) {
                case 'height imperial':
                    document.getElementById('mapme-height-feet').focus();
                    break;
                case 'height metric':
                    document.getElementById('mapme-height-cm').focus();
                    break;
                case 'weight imperial':
                    document.getElementById('mapme-weight-stone').focus();
                    break;
                case 'weight metric':
                    document.getElementById('mapme-weight-kg').focus();
                    break;
            }

        }, 300)


    }
    checkForm = () => {
        var status = false;
        this.errors = [];
        if (isNaN(this.state.kg) || this.state.kg === '') {
            status = true;
        }
        if (isNaN(this.state.cm) || this.state.cm === '') {
            status = true;
        }
        if (this.state.DOB === null) {
            status = true;
        }
        if (this.state.DOM === null && this.state.domState === 'mapme-slidedn') {
            status = true;
        }
        if (this.state.domState === '') {
            status = true;
        }
        if (!status && this.submitted) {
            if (this.state.age < 2) {
                this.errors['dob'] = this.props.data.text_areas.error_age_low
                this.warnings['dob'] = false
            }
            if (this.state.age >= 18) {
                this.errors['dob'] = this.props.data.text_areas.error_age_high
                this.warnings['dob'] = false
            }
            if (this.state.cm > 240) {
                this.errors['height'] = this.props.data.text_areas.error_height_high
            }
            if (this.state.cm < 5) {
                this.errors['height'] = this.props.data.text_areas.error_height_low
            }
            if (this.state.kg > 318) {
                this.errors['weight'] = this.props.data.text_areas.error_weight_high
            }
            if (this.state.kg < 2) {
                this.errors['weight'] = this.props.data.text_areas.error_weight_low
            }
            if (Object.keys(this.errors).length === 0 && this.submitted) {
                this.submitted = false
            }
        }

        if (!status) {
            this.props.Confirmation.current.setState({
                'age': Math.floor(this.age),
                'dob': this.state.DOB.getDate() + '/' + (this.state.DOB.getMonth() + 1) + '/' + (this.state.DOB.getYear() + 1900)
            })
            if (this.state.heightUnit === 'metric') {
                this.props.Confirmation.current.setState({
                    'height': this.state.cm + 'cm'
                })
            } else {
                this.props.Confirmation.current.setState({
                    'height': this.state.feet + 'ft ' + Math.max(0, this.state.inches) + 'in'
                })

            }
            if (this.state.weightUnit === 'metric') {
                this.props.Confirmation.current.setState({
                    'weight': this.state.kg + 'kg'
                })
            } else {
                this.props.Confirmation.current.setState({
                    'weight': this.state.stone + 'st ' + Math.max(0, this.state.pounds) + 'lb'
                })
            }
            if (this.state.DOM !== null && this.state.domState === 'mapme-slidedn') {
                this.props.Confirmation.current.setState({
                    'dom': this.state.DOM.getDate() + '/' + (this.state.DOM.getMonth() + 1) + '/' + (this.state.DOM.getYear() + 1900)
                })
            } else {
                this.props.Confirmation.current.setState({
                    'dom': 'Today'
                })
            }
        }
        this.setState({ disabled: status, age: this.age })
        if (this.state.dobYear.toString().length === 4 && this.state.dobMonth.toString().length > 0 && this.state.dobDay.toString().length > 0) {
            this.checkAgeWarning()
        }
        this.props.TrackPageHeight(300);
    };
    setAge() {

        if (this.state.DOB != null) {
            this.state.DOB.setHours(0, 0, 0);
            if (this.state.domState === 'mapme-slidedn' && this.state.DOM != null) {
                this.state.DOM.setHours(0, 0, 0);
                console.log('DOB = ' + this.state.DOB.getTime())
                console.log('DOM = ' + this.state.DOM.getTime())
                this.age = (this.state.DOM.getTime() - this.state.DOB.getTime()) / 31557600000;
            } else {
                this.age = (new Date().getTime() - this.state.DOB.getTime()) / 31557600000
            }
            this.setState({ age: this.age }, function () {
                this.checkForm()
            })
        }

        if (this.state.dobYear.toString().length === 4 && this.state.dobMonth.toString().length > 0 && this.state.dobDay.toString().length > 0) {
            this.checkAgeWarning()
        }

    }
    checkAgeWarning() {
        this.warnings['dob'] = false
        if (this.props.Form1.current.state.age === 'REC' && (this.age < 4 || this.age >= 6)) {
            this.errors['dob'] = this.props.data.text_areas.warning_year_group_REC
            this.warnings['dob'] = true
        }
        if (this.props.Form1.current.state.age === 'YR6' && (this.age < 10 || this.age >= 12)) {
            this.errors['dob'] = this.props.data.text_areas.warning_year_group_YR6
            this.warnings['dob'] = true
        }
        if (this.state.age < 2) {
            this.errors['dob'] = this.props.data.text_areas.error_age_low
            this.warnings['dob'] = false
        }
        if (this.state.age >= 18) {
            this.errors['dob'] = this.props.data.text_areas.error_age_high
            this.warnings['dob'] = false
        }
        this.setState({ age: this.age })
    }
    checkDOB(event) {
        this.props.SetPageHeight();
        var val = event.target.value
        var ignore = false;
        var tmp = event.target.id.split('-');
        if (val !== '' && val !== '0' && val !== 0) {
            val = this.checkNum(parseFloat(val))
            switch (tmp[1]) {
                case "dobDay":
                    val = Math.max(1, Math.min(val, 31))
                    break;
                case "dobMonth":
                    val = Math.max(1, Math.min(val, 12))
                    break;
                case "dobYear":
                    val = Math.min(val, (1900 + this.now.getYear()))
                    break;
                default:
                    break;
            }
        } else {
            ignore = true;
        }
        this.setState({ [tmp[1]]: val }, function () {
            if (!isNaN(this.state.dobDay) && !isNaN(this.state.dobMonth) && !isNaN(this.state.dobYear) && this.state.dobYear.toString().length === 4 && !ignore) {
                var tmp_date = new Date(this.state.dobYear, this.state.dobMonth - 1, this.state.dobDay);
                this.setState({ DOB: tmp_date, dobDay: tmp_date.getDate(), dobMonth: (tmp_date.getMonth() + 1), dobYear: (1900 + tmp_date.getYear()) }, function () {
                    this.setAge()
                }.bind(this))
            } else {
                this.setState({ DOB: null }, function () {
                    this.setAge()
                }.bind(this))
            }
        }.bind(this))
    }
    checkDOM(event) {
        var val = event.target.value
        var tmp = event.target.id.split('-');
        var ignore = false
        if (val !== '' && val !== '0' && val !== 0) {
            val = this.checkNum(parseFloat(val))
            switch (tmp[1]) {
                case "domDay":
                    val = Math.max(1, Math.min(val, 31))
                    break;
                case "domMonth":
                    val = Math.max(1, Math.min(val, 12))
                    break;
                case "domYear":
                    val = Math.min(val, (1900 + this.now.getYear()))
                    break;
                default:
                    break;
            }
        } else {
            ignore = true
        }
        this.setState({ [tmp[1]]: val }, function () {
            if (!isNaN(this.state.domDay) && !isNaN(this.state.domMonth) && !isNaN(this.state.domYear) && this.state.domYear.toString().length === 4 && !ignore) {
                var now = new Date();
                var tmp_date = new Date(this.state.domYear, this.state.domMonth - 1, this.state.domDay);
                if (now.getTime() - tmp_date.getTime() < 0) {
                    tmp_date = new Date();
                }
                this.setState({ DOM: tmp_date, domDay: tmp_date.getDate(), domMonth: (tmp_date.getMonth() + 1), domYear: (1900 + tmp_date.getYear()) }, function () {
                    this.setAge()
                }.bind(this))
            } else {
                this.setState({ DOM: null }, function () {
                    this.setAge()
                }.bind(this))

            }
        }.bind(this))
    }
    checkNum(val) {
        if (isNaN(val)) {
            return 0;
        } else {
            return val;
        }
    }
    checkErrors() {
        this.submitted = true;
        this.checkForm();
        var c = 0;
        for (const key in this.errors) {
            if (!this.warnings[key]) {
                c++
            }
        }
        if (c > 0) {
            document.getElementById("mapme-" + Object.keys(this.errors)[0]).scrollIntoView({ behavior: 'smooth', block: 'end' });
        } else {
            this.props.navigate("Confirmation", false)
        }
    }
    getError(id) {
        if (this.errors.hasOwnProperty(id)) {
            return this.errors[id]
        } else {
            return null;
        }
    }
    checkUnits(event) {
        var val = event.target.value;
        var tmp = event.target.id.split('-');
        var feet, inches, cm, stone, pounds, kg;
        if (val !== '') {
            val = this.checkNum(parseFloat(val))
            switch (event.target.id) {
                case "mapme-height-cm":
                    feet = Math.floor(val / 30.48);
                    inches = Math.round(val / 2.54) - (feet * 12);
                    this.setState({ 'feet': feet, 'inches': inches });
                    break;
                case "mapme-height-feet":
                    cm = Math.round((val * 30.48) + (this.checkNum(this.state.inches) * 2.54));
                    this.setState({ 'cm': cm });
                    break;
                case "mapme-height-inches":
                    cm = Math.round((this.checkNum(this.state.feet) * 30.48) + (val * 2.54));
                    this.setState({ 'cm': cm });
                    break;
                case "mapme-weight-kg":
                    stone = Math.floor(val * 0.157473);
                    pounds = Math.round(val * 2.204622) - (stone * 14);
                    this.setState({ 'stone': stone, 'pounds': pounds });
                    break;
                case "mapme-weight-stone":
                    kg = Math.round((val / 0.157473) + (this.checkNum(this.state.pounds) / 2.204622));
                    this.setState({ 'kg': kg });
                    break;
                case "mapme-weight-pounds":
                    kg = Math.round((this.checkNum(this.state.stone) / 0.157473) + (val / 2.204622));
                    this.setState({ 'kg': kg });
                    break;
                default:
                    break;
            }

        }
        this.setState({ [tmp[2]]: val }, function () {
            this.checkForm();
        }.bind(this));

    }
    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value }, this.checkForm);
    }
    toggleDom(event) {
        var val = 'mapme-slideup';
        if (event.target.value === '1') {
            val = 'mapme-slidedn';
            document.getElementById('mapme-dom-panel').style.visibility = "visible"
        } else {
            setTimeout(function () {
                document.getElementById('mapme-dom-panel').style.visibility = "hidden"
            }, 300);
        }
        this.setState({ 'domState': val }, function () {
            this.props.TrackPageHeight(300);
            this.checkForm();
        }.bind(this));

    }
    render() {
        return (
            <div className={`mapme-page ${this.state.AnimationState}`} style={{ visibility: this.state.visibility }} id="mapme-EnterYourDetails">
                <button type="button" className="mapme-app-back-button mapme-desktop" onClick={() => this.props.navigate("SelectChild", true)}>Back</button>
                <h2>{parse(this.props.data.text_areas.form2_title)}</h2>
                <p className="mapme-form-header">{parse(this.props.data.text_areas.form2_explanation)}</p>
                <div className="mapme-chosen-shape"><span>You chose body shape {this.state.ChildWeightCat}</span><img className="mapme-desktop" src={require('../' + this.props.getFileName(this.state.ChildWeightCat, 32)).default} alt={"Child of weight category " + (this.state.ChildWeightCat)} /></div>
                <div className="mapme-form-panel" id="mapme-dob">
                    <fieldset aria-describedby="mapme-dob-hint" role="group" className={`${this.warnings.dob === true ? 'mapme-warning' : ''} ${this.getError('dob') === null ? '' : 'mapme-error'}`} >
                        <legend >
                            <h3>Date of birth</h3>
                        </legend>
                        <div>
                            <div className={`mapme-error-msg  ${this.getError('dob') === null && this.warnings['dob'] === false? 'mapme-slideup' : 'mapme-slidedn'} ${this.warnings.dob === true ? 'mapme-warning' : ''}`}>{this.getError('dob')}</div>
                            <span className="mapme-hint">For example, 15 3 2013</span>
                            <div className="mapme-inline-group">
                                <div >
                                    <div className="mapme-stacked-group">
                                        <label htmlFor="mapme-dobDay">Day</label>
                                        <input className="mapme-input--width-2" value={this.state.dobDay} onChange={this.checkDOB} id="mapme-dobDay" type="number" min="1" max="31" pattern="[0-9]*" name="mapme-dobDay" ></input>
                                    </div>
                                </div>
                                <div >
                                    <div className="mapme-stacked-group">
                                        <label htmlFor="mapme-dobMonth">Month</label>
                                        <input className="mapme-input--width-2" value={this.state.dobMonth} onChange={this.checkDOB} id="mapme-dobMonth" type="number" min="1" max="12" pattern="[0-9]*" name="mapme-dobMonth" ></input>
                                    </div>
                                </div>
                                <div >
                                    <div className="mapme-stacked-group">
                                        <label htmlFor="mapme-dobYear">Year</label>
                                        <input className="mapme-input--width-4" value={this.state.dobYear} onChange={this.checkDOB} id="mapme-dobYear" type="number" pattern="[0-9]*" name="mapme-dobYear" ></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="mapme-form-panel" id="mapme-height">
                    <fieldset role="group" className={this.getError('height') === null ? '' : 'mapme-error'}>
                        <legend >
                            <h3>Height</h3>
                        </legend><div>

                            <div className={`mapme-error-msg ${this.getError('height') === null ? 'mapme-slideup' : 'mapme-slidedn'}`}>{this.getError('height')}</div>
                            <div className="mapme-inline-group">
                                <div className={`mapme-imperial ${(this.state.heightUnit === 'imperial' ? 'mapme-fadein' : 'mapme-fadeout')}`} >
                                    <div className="mapme-stacked-group">
                                        <label className="mapme-label" htmlFor="mapme-height-feet">Feet</label>
                                        <input className="mapme-input--width-2" value={this.state.feet} onChange={this.checkUnits} id="mapme-height-feet" name="mapme-height-feet" disabled={this.state.heightUnit === 'metric'} type="number" pattern="[0-9]*"></input>
                                    </div>
                                </div>
                                <div className={`mapme-imperial ${(this.state.heightUnit === 'imperial' ? 'mapme-fadein' : 'mapme-fadeout')}`}>
                                    <div className="mapme-stacked-group">
                                        <label className="mapme-label" htmlFor="mapme-height-inches">Inches</label>
                                        <input className="mapme-input--width-2" value={this.state.inches} onChange={this.checkUnits} id="mapme-height-inches" name="mapme-height-inches" disabled={this.state.heightUnit === 'metric'} type="number" pattern="[0-9]*"></input>
                                    </div>
                                </div>
                                <div className={`mapme-metric ${(this.state.heightUnit === 'metric' ? 'mapme-fadein' : 'mapme-fadeout')}`}>
                                    <div className="mapme-stacked-group">
                                        <label className="mapme-label " htmlFor="mapme-height-cm">Centimetres</label>
                                        <input className="mapme-input--width-4" value={this.state.cm} onChange={this.checkUnits} id="mapme-height-cm" name="mapme-height-cm" disabled={this.state.heightUnit === 'imperial'} type="number" step="0.1"></input>
                                    </div>
                                </div>
                            </div>
                            <div className="mapme-unit-toggle">
                                <button onClick={() => this.toggleUnits('height', 'metric')} id="mapme-unit-toggle-HM" className={(this.state.heightUnit === 'imperial' ? 'mapme-fadein' : 'mapme-fadeout')} disabled={this.state.heightUnit === 'metric'}>Switch to Metric</button>
                                <button onClick={() => this.toggleUnits('height', 'imperial')} id="mapme-unit-toggle-HI" className={(this.state.heightUnit === 'metric' ? 'mapme-fadein' : 'mapme-fadeout')} disabled={this.state.heightUnit === 'imperial'}>Switch to Imperial</button>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="mapme-form-panel" id="mapme-weight">
                    <fieldset role="group" className={this.getError('weight') === null ? '' : 'mapme-error'}>
                        <legend >
                            <h3>Weight</h3>
                        </legend>
                        <div>
                            <div className={`mapme-error-msg ${this.getError('weight') === null ? 'mapme-slideup' : 'mapme-slidedn'}`}>{this.getError('weight')}</div>
                            <div className="mapme-inline-group">
                                <div className={`mapme-imperial ${(this.state.weightUnit === 'imperial' ? 'mapme-fadein' : 'mapme-fadeout')}`}>
                                    <div className="mapme-stacked-group">
                                        <label className="mapme-label" htmlFor="mapme-weight-stone">Stone</label>
                                        <input className="mapme-input--width-2" value={this.state.stone} onChange={this.checkUnits} id="mapme-weight-stone" name="mapme-weight-stone" type="number" disabled={this.state.weightUnit === 'metric'} pattern="[0-9]*"></input>
                                    </div>
                                </div>
                                <div className={`mapme-imperial ${(this.state.weightUnit === 'imperial' ? 'mapme-fadein' : 'mapme-fadeout')}`}>
                                    <div className="mapme-stacked-group">
                                        <label className="mapme-label" htmlFor="mapme-weight-lb">Pounds</label>
                                        <input className="mapme-input--width-2" value={this.state.pounds} onChange={this.checkUnits} id="mapme-weight-pounds" name="mapme-weight-pounds" type="number" disabled={this.state.weightUnit === 'metric'} pattern="[0-9]*"></input>
                                    </div>
                                </div>
                                <div className={`mapme-metric ${(this.state.weightUnit === 'metric' ? 'mapme-fadein' : 'mapme-fadeout')}`}>
                                    <div className="mapme-stacked-group">
                                        <label className="mapme-label " htmlFor="mapme-weight-kg">Kilograms</label>
                                        <input className="mapme-input--width-2" value={this.state.kg} onChange={this.checkUnits} id="mapme-weight-kg" name="mapme-weight-kg" type="number" disabled={this.state.weightUnit === 'imperial'} step="0.1"></input>
                                    </div>
                                </div>
                            </div>
                            <div className="mapme-unit-toggle">
                                <button onClick={() => this.toggleUnits('weight', 'metric')} id="mapme-unit-toggle-WM" className={(this.state.weightUnit === 'imperial' ? 'mapme-fadein' : 'mapme-fadeout')} disabled={this.state.weightUnit === 'metric'} >Switch to Metric</button>
                                <button onClick={() => this.toggleUnits('weight', 'imperial')} id="mapme-unit-toggle-WI" className={(this.state.weightUnit === 'metric' ? 'mapme-fadein' : 'mapme-fadeout')} disabled={this.state.weightUnit === 'imperial'}>Switch to Imperial</button>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="mapme-form-panel" id="mapme-dom">
                    <fieldset >
                        <legend>
                            <h3>Date height/weight were measured</h3>
                        </legend>
                        <div>
                            <div>
                                <div >
                                    <input id="mapme-dom-0" name="dom" onChange={this.toggleDom} type="radio" value="0" checked={this.state.domState === "mapme-slideup"} />
                                    <label htmlFor="mapme-dom-0">Today</label>
                                </div>
                                <div >
                                    <input id="mapme-dom-1" name="dom" onChange={this.toggleDom} type="radio" value="1" checked={this.state.domState === "mapme-slidedn"} />
                                    <label htmlFor="mapme-dom-1">Not today</label>
                                </div>
                            </div>
                        </div>

                    </fieldset>
                    <fieldset className={`mapme-dom-panel ${this.state.domState}`} id="mapme-dom-panel" style={{ visibility: 'hidden' }} role="group">
                        <legend >
                            <h3>Date of measurement</h3>
                        </legend>
                        <div className="mapme-inline-group">
                            <div >
                                <div className="mapme-stacked-group">
                                    <label htmlFor="mapme-dom-day">Day</label>
                                    <input className="mapme-input--width-2" tabIndex={this.state.domState === 'mapme-slidedn' ? null : -1} value={this.state.domDay} onChange={this.checkDOM} id="mapme-domDay" type="number" min="1" max="31" pattern="[0-9]*" name="mapme-dom-day" ></input>
                                </div>
                            </div>
                            <div >
                                <div className="mapme-stacked-group">
                                    <label htmlFor="mapme-dom-month">Month</label>
                                    <input className="mapme-input--width-2" tabIndex={this.state.domState === 'mapme-slidedn' ? null : -1} value={this.state.domMonth} onChange={this.checkDOM} id="mapme-domMonth" type="number" min="1" max="12" pattern="[0-9]*" name="mapme-dom-month" ></input>
                                </div>
                            </div>
                            <div >
                                <div className="mapme-stacked-group">
                                    <label htmlFor="mapme-dom-year">Year</label>
                                    <input className="mapme-input--width-4" tabIndex={this.state.domState === 'mapme-slidedn' ? null : -1} value={this.state.domYear} onChange={this.checkDOM} id="mapme-domYear" type="number" pattern="[0-9]*" name="mapme-dom-year" ></input>
                                </div>
                            </div>
                        </div>

                    </fieldset>
                    <details className="mapme-details"><summary onClick={() => this.props.TrackPageHeight(100)}><div>Why are we asking this?</div></summary><span>{parse(this.props.data.text_areas.dob_explanation)}</span></details>
                </div>

                <button type="button" className="mapme-app-button" onClick={this.checkErrors} disabled={this.state.disabled} >Check your choice</button>
            </div>
        )
    }
}
export default EnterYourDetails;