import React from 'react'
import {Component} from 'react' 
class footer extends Component {   
    constructor(props) {
        super(props);
        this.state = {
            AnimationState:"mapme-fadein",
            locked:false
        };
    }
    render(){
        return(
            <div className={`mapme-footer ${this.state.AnimationState}`}>
                <div className= "mapme-page">
                <div>
                <button disabled={this.state.locked}  onClick={(e) => this.props.modal.current.launch('About',this.props.data.text_areas.modal_about)}>About MapMe2</button>
                <button disabled={this.state.locked} onClick={(e) => this.props.modal.current.launch('Contact us',this.props.data.text_areas.modal_contact)}>Contact us</button>
                <button disabled={this.state.locked} onClick={(e) => this.props.modal.current.launch('Privacy policy',this.props.data.text_areas.modal_privacy_policy)}>Privacy policy</button>
                </div>
                <div>© 2024 Newcastle University</div>
                </div>
            </div>
        )
    }
}

export default footer;