import React from 'react'
import { Component } from 'react'
class Form1 extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            AnimationState: "mapme-fadeout",
            visibility:"hidden",
            disabled:true,
            sex:null,
            age:null
        };
    }
    reset(){
        this.setState({
            AnimationState: "mapme-fadeout",
            visibility:"hidden",
            disabled:true,
            sex:null,
            age:null
        })
    }
    testData(){
        this.setState({
            sex: 'F',
            age: 'YR6'
        })
    }
    checkForm = () =>{
        if(this.state.sex != null && this.state.age != null){
            this.setState({disabled:false})
            this.props.SelectChild.current.forceUpdate();
        }
    };
    handleChange(event) {
        this.setState({[event.target.name]: event.target.value},this.checkForm);
        this.props.TrackPageHeight(300)
    }
    render() {
        return (
            <div className={`mapme-page ${this.state.AnimationState}`} style={{visibility:this.state.visibility}} id="mapme-Form1">
                 <button type="button" tabIndex={this.state.locked ? -1 : null} className="mapme-desktop mapme-app-back-button" onClick={() => this.props.navigate("Start", true)}>Back</button>
                <h2>First select the sex and age of your child</h2>
                <div className="mapme-form-panel">
                    <fieldset >
                        <legend>
                            <h3>Sex</h3>
                        </legend>
                        <div>
                            <div >
                                <input id="mapme-sex-0" name="sex" onChange={this.handleChange} type="radio" value="M" checked={this.state.sex === "M"}/>
                                <label htmlFor="mapme-sex-0">Male</label>
                            </div>
                            <div >
                                <input id="mapme-sex-1" name="sex"  onChange={this.handleChange} type="radio" value="F" checked={this.state.sex === "F"}/>
                                <label htmlFor="mapme-sex-1">Female</label>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="mapme-form-panel">
                    <fieldset >
                        <legend>
                            <h3>Age</h3>
                        </legend>
                        <div>
                            <div >
                                <input id="mapme-age-0" name="age"  onChange={this.handleChange} type="radio" value="REC" checked={this.state.age === "REC"}/>
                                <label htmlFor="mapme-age-0">Reception (age 4-5 years)</label>
                            </div>
                            <div >
                                <input id="mapme-age-1" name="age"  onChange={this.handleChange} type="radio" value="YR6" checked={this.state.age === "YR6"}/>
                                <label htmlFor="mapme-age-1">Year 6 (10-11 years)</label>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <button type="button" className="mapme-app-button" disabled={this.state.disabled}  onClick={() => this.props.navigate("SelectChild",false)}>Continue</button>
            </div>
        )
    }
}

export default Form1;