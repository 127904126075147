import React from 'react'
import { Component } from 'react'
import { Swipeable } from 'react-swipeable'


class ExpandAdult extends Component {
    constructor(props) {
        super(props);
        this.swipingHandler = this.swipingHandler.bind(this);
        this.swipeEndHandler = this.swipeEndHandler.bind(this);
        this.rotate = this.rotate.bind(this);
        this.setProgress = this.setProgress.bind(this);
        this.checkCancel = this.checkCancel.bind(this);
        this.state = {
            AnimationState: "",
            AnimationType: "",
            ChildWeightCat: null,
            Rotation: 0,
            Hint: 'mapme-fadein',
            h: 0,
            active: false,
            preloaded: false
        };
        this.swiping = false;
        this.swipe_origin = null;
        this.total_images = 36;
        this.images_loaded = 0
        this.progressStyle = {
            width: '0%'
        };
        this.preloadStyle = {
            display: 'block'
        };
        this.r_interval = null;
        this.r_val = 0;
        this.launch = this.launch.bind(this);
        this.die = this.die.bind(this);
        this.config = {
            preventDefaultTouchmoveEvent: true,
            trackMouse: true
        };
    }
    swipeEndHandler(e) {
        setTimeout(function () {
            this.swiping = false;
            console.log(this.state)
        }.bind(this), 100)

        this.swipe_origin = null;
        this.start_frame = this.state.Rotation;
    }
    swipingHandler(e) {
        if (!this.swiping) {
            this.swiping = true;
            this.swipe_origin = e.deltaX;
            this.start_frame = this.state.Rotation;
        } else {
            var dist = (this.swipe_origin - e.deltaX) / window.innerWidth;
            var mult = 20
            if (window.innerWidth > 640) {
                mult = 40
            }
            var r = this.start_frame + Math.round(dist * mult)
            if (r < 0) {
                r += 35
            }
            if (r > 35) {
                r -= 35
            }
            this.setState({ Rotation: r, Hint: 'mapme-fadeout' })
        }
    }
    checkCancel(e) {
        if (e.target.getAttribute('class') != null && !this.swiping) {
            if (e.target.getAttribute('class').indexOf('mapme-expand-adult') > -1) {
                this.die();
            }
        }

    }
    setProgress() {
        this.images_loaded++
        if (this.images_loaded === this.total_images && this.images_loaded > 0) {
            setTimeout(function () {
                this.preloadStyle = {
                    display: 'none'
                };
                this.forceUpdate();
            }.bind(this), 600)
        }
        this.progressStyle = {
            width: ((this.images_loaded / this.total_images) * 100) + '%'
        };
        this.forceUpdate();


    }
    rotate(val,event) {
        var status = true;
        if(event!==undefined && val !== 0){
            status = false;
            if(event.keyCode === 32 || event.keyCode === 13){
                status = true;
            }
        };
        if(status){
            clearInterval(this.r_interval);
            this.r_val = val;
            if (val !== 0) {
                console.log('rotation started')
                this.r_interval = setInterval(function () {
                    var r = this.state.Rotation + this.r_val;
                    if (r < 0) {
                        r = 35
                    }
                    if (r > 35) {
                        r = 0
                    }
                    this.setState({ 'Hint': 'mapme-fadeout', 'Rotation': r });
                }.bind(this), 30)
            }
        }
    }
    launch(val) {
        if(val!= null){
        this.images_loaded = 0;
        this.progressStyle = {
            width: '0%'
        };
        this.preloadStyle = {
            display: 'block'
        };

        this.setState({
            ChildWeightCat: val,
            AnimationState: "mapme-fadein",
            AnimationType: "mapme-snap",
            Rotation: 0
        });
       
        setTimeout(() => { 
            this.props.lockRoot(true);
            this.setState({ AnimationType: "" });
            if(this.props.keynav){
                document.querySelector('.mapme-expand-adult .mapme-close').focus()
            }
        }, 300);
    }
    }
    die() {
        this.images_loaded = 0;
        this.setState({
            AnimationState: "mapme-fadeout"
        });
        this.props.TrackPageHeight(900);
        setTimeout(() => {
            this.props.lockRoot(false);
            this.setState({ AnimationState: "" });  
        }, 600);
    }
    render() {
        return (
            <Swipeable onSwiping={this.swipingHandler} {...this.config} onSwiped={this.swipeEndHandler} {...this.config}>
                <div className={`mapme-expand-adult ${this.state.AnimationState}`} onMouseUp={() => this.rotate(0)}  onKeyUp={() => this.rotate(0)}  onClick={(e) => this.checkCancel(e)}>
                    <div>
                        <div className="mapme-rotate-view"><div>
                            {Array.from({ length: 36 }, (_, k) => (
                                <div key={k}>
                                    <img src={require('../' + this.props.getAdultImg(this.state.ChildWeightCat, k,this.state.active)).default} draggable="false" onLoad={(e) => this.setProgress(e)} alt={"Adult of weight category " + this.state.ChildWeightCat} className={(k === this.state.Rotation ? 'mapme-rotateIn' : 'mapme-rotateOut')} />
                                </div>
                            ))}
                        </div></div>
                        <button onMouseDown={() => this.rotate(-1)} onKeyDown={(event) => this.rotate(-1,event)}  className="mapme-left-arrow mapme-desktop" title="Rotate left"></button>
                        <button onMouseDown={() => this.rotate(1)} onKeyDown={(event) => this.rotate(1,event)}   className="mapme-right-arrow mapme-desktop" title="Rotate right"></button>
                        <div className={`mapme-touchicon ${this.state.Hint}`}><span className="mapme-desktop">Drag or use<br/>arrows to rotate</span><span className="mapme-mobile">Drag to rotate</span></div>
                        <button onClick={this.die} className="mapme-close" title="close">Close</button>
                    </div>
                    <div style={this.preloadStyle} className={`mapme-preload ${((this.total_images === this.images_loaded) && (this.total_images > 0) ? 'mapme-fadeout' : '')}`}>
                        <div><div style={this.progressStyle}></div></div>
                        <div className="mapme-preload-label">Loading</div>
                    </div>
                </div>
            </Swipeable>
        )
    }
}
export default ExpandAdult;