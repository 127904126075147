import React from 'react'
import { Component } from 'react'
class Confirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AnimationState: "mapme-fadein-delayed",
            visibility: "visible",
            tabIndex: null,
            locked: false,
            confirmationCopy: '',
            age: 0,
            dob: '',
            weight: '',
            height: '',
            dom:'Today'
        };
    }
    testData() {
        this.setState({
            age: 13 
        })

    }
    reset() { }
    render() {
        return (
            <div className={`mapme-page ${this.state.AnimationState}`} style={{ visibility: this.state.visibility }} id="mapme-Confirmation">
                <button type="button" className="mapme-app-back-button mapme-desktop" onClick={() => this.props.navigate("EnterYourDetails", true)}>Back</button>
                <h2>You entered</h2>
                <div className="mapme-form-panel">
                    <p>Age: <strong>{this.state.age}</strong></p>
                    <p>Date of birth: <strong>{this.state.dob}</strong></p>
                    <p>Height: <strong>{this.state.height}</strong></p>
                    <p>Weight: <strong>{this.state.weight}</strong></p>
                    <p>Date  measured: <strong>{this.state.dom}</strong></p>
                    <button className = "mapme-excuse-button" onClick={() => this.props.navigate("EnterYourDetails", true)}>Change</button>
                </div>
                <button type="button" tabIndex={this.state.tabIndex} className="mapme-app-button" id="mapme-start-button" onClick={() => this.props.navigate("Results", false)}>Continue</button>

            </div>
        )
    }
}

export default Confirmation;