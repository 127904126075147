import React from 'react'
import { Component } from 'react'
import parse from 'html-react-parser';

class Modal extends Component {
    constructor(props) {
        super(props);
        this.checkCancel = this.checkCancel.bind(this);
        this.state = {
            AnimationState: "",
            AnimationType: "",
            h: 0,
            active: false,
            Content: '',
            Title: ''
        };
        this.launch = this.launch.bind(this);
        this.die = this.die.bind(this);
    }
    checkCancel(e) {
        if (e.target.getAttribute('class') != null) {
            if (e.target.getAttribute('class').indexOf('mapme-modal') > -1) {
                this.die();
            }
        }

    }
    launch(title,content) {
       this.setState({
            Title: title,
            Content: content,
            AnimationState: "mapme-fadein",
            AnimationType: "mapme-snap"

        });
       this.props.lockRoot(true);
        setTimeout(() => {
            this.setState({ AnimationType: "" });
            if (this.props.keynav) {
                document.querySelector('.mapme-modal .mapme-close').focus()
            }
           document.getElementById('mapme-modal-inner').scrollTop = 0;
        }, 300);
    }
    die() {

        this.setState({
            AnimationState: "mapme-fadeout"
        });
        this.props.lockRoot(false);
        this.props.TrackPageHeight(600)
        setTimeout(() => {
            this.setState({ AnimationState: "" });
            
            
        }, 600);
    }
    render() {
        return (
            <div className={`mapme-modal ${this.state.AnimationState}`} onClick={(e) => this.checkCancel(e)}>
                <div><div id = "mapme-modal-inner"><h2>{parse(this.state.Title)}</h2>{parse(this.state.Content)}</div>
                    <button onClick={this.die} className="mapme-close" title="close">Close</button>
                </div>

            </div>
        )
    }
}
export default Modal;