import React from 'react'
import { Component } from 'react'
import { Swipeable } from 'react-swipeable'
import parse from 'html-react-parser';

class SelectChild extends Component {
    constructor(props) {
        super(props);
        this.setChild = this.setChild.bind(this);
        this.nav = this.nav.bind(this);
        this.swipeHandler = this.swipeHandler.bind(this);
        this.swipingHandler = this.swipingHandler.bind(this);
        this.aboutButton = this.aboutButton.bind(this);
        this.detailsEvent = this.detailsEvent.bind(this);
        this.setCarousel = this.setCarousel.bind(this);
        this.radioEvent = this.radioEvent.bind(this);
        this.expand = this.expand.bind(this);
        this.swiping = false;
        this.SwipeOrigin = null;
        this.genders = ['M', 'F'];
        this.AgeGroups = ['REC', 'YR6'];
        this.state = {
            AnimationState: "mapme-fadeout",
            AnimationType: "",
            visibility: "hidden",
            ChildWeightCat: null,
            CarouselState: 4,
            disabled: true,
            locked: false,
            active: true,
            preloaded: false,
            title: '',
            xpos: -300,
            expandLabel: 'Tap to enlarge'
        };
        this.CarouselStyle = {
            marginLeft: '-300%'
        };
        this.config = {
            preventDefaultTouchmoveEvent: false,
            trackMouse: true
        };
    }
    reset() {
        this.setState({
            AnimationState: "mapme-fadeout",
            AnimationType: "",
            visibility: "hidden",
            ChildWeightCat: null,
            CarouselState: 4,
            disabled: true,
            locked: false,
            active: true,
            xpos: -300
        })
        this.CarouselStyle = {
            marginLeft: '-300%'
        };
    }
    swipeHandler(e) {
        if (!isNaN(this.CarouselX)) {
            var SwipeFinalX = Math.round(this.CarouselX * 0.01) * 100
            SwipeFinalX = Math.min(Math.max(-600, SwipeFinalX), 0);
            this.CarouselStyle = {
                marginLeft: SwipeFinalX + '%'
            };
            var c = Math.abs(Math.round(SwipeFinalX / 100)) + 1;
            this.setState({ 'CarouselState': c });
            if (this.state.ChildWeightCat != null) {
                this.setState({ 'ChildWeightCat': c });
            }
        }
        this.swiping = false;
        this.SwipeOrigin = null;
        this.SwipeDirection = null;
        this.CarouselOrigin = null;
        this.forceUpdate();
        document.body.classList.remove('swipe-lock');

    }
    swipingHandler(e) {

        if (!this.swiping) {
            this.swiping = true;
            this.SwipeOrigin = e.deltaX;
            this.SwipeDirection = null;
            this.CarouselOrigin = parseFloat(this.CarouselStyle.marginLeft.replace('%', ''));
        } else {
            var dist = (this.SwipeOrigin - e.deltaX) / window.innerWidth;
            if (Math.abs(dist) > 0.1) {
                document.body.classList.add('swipe-lock');
                var modifier = 1000 * (Math.pow(Math.abs(dist), 0.75));
                this.SwipeDirection = dist > 0 ? 'right' : 'left';
                this.CarouselX = this.CarouselOrigin + (dist * modifier)
                this.CarouselX = Math.min(Math.max(-600, this.CarouselX), 0);
                this.CarouselStyle = {
                    marginLeft: this.CarouselX + '%'
                };
                this.forceUpdate();
            }
        }
    }
    testData() {
        this.setState({
            ChildWeightCat: 4
        }, function () {
            this.checkForm();
        }.bind(this))
    }
    checkForm = () => {
        if (this.state.ChildWeightCat != null) {
            this.setState({ disabled: false })
            this.setCarousel();
            //'sc-title-'+this.props.Form1.current.state.sex+'-'+this.props.Form1.current.state.age
        }
    };
    setCarousel() {
        console.log('set parent carousel to ' + this.state.CarouselState)
        if (this.state.CarouselState != null) {
            this.CarouselStyle = {
                marginLeft: (100 - ((this.state.CarouselState) * 100)) + '%'
            };
            this.forceUpdate();
        }
    }
    expand(value) {
        if (value == null) {
            value = 4;
        }
        this.props.TrackPageHeight(300)
        this.props.ExpandChild.current.setState({ ChildWeightCat: parseInt(value) })

        setTimeout(function () {
            this.setState({ 'expandLabel': 'Selected', 'ChildWeightCat': parseInt(value), 'CarouselState': parseInt(value) }, this.checkForm);
        }.bind(this), 600)

        this.props.ExpandChild.current.launch(parseInt(value), true)
    }
    radioEvent(event) {
        if (window.innerWidth < 1024) {
            var cat = Math.max(1, Math.min(7, parseInt(event.target.value)))
            if (this.state.ChildWeightCat === null) {
                this.setState({ 'CarouselState': cat }, function () {
                    this.setCarousel();
                });
            } else {
                this.setState({ 'ChildWeightCat': cat, 'CarouselState': cat }, this.checkForm);
                this.props.ExpandChild.current.setState({ ChildWeightCat: parseInt(event.target.value) })
                this.props.EnterYourDetails.current.setState({ ChildWeightCat: parseInt(event.target.value) })
            }
        } else {
            this.setChild(event)
        }

    }
    setChild(event) {
        var cat = Math.max(1, Math.min(7, parseInt(event.target.value)))
        this.setState({ 'ChildWeightCat': cat, 'CarouselState': cat }, this.checkForm);
        this.props.ExpandChild.current.setState({ ChildWeightCat: parseInt(event.target.value) })
        this.props.EnterYourDetails.current.setState({ ChildWeightCat: parseInt(event.target.value) })
        this.expand(cat)
    }
    aboutButton(){
        this.props.modal.current.launch('About',this.props.data.text_areas.modal_about)
    }
    detailsEvent(event){
        console.log('details event')
        console.log(event)
    }
    nav(val) {
        if (this.state.active) {
            var cwc = this.state.CarouselState;
            if (this.state.ChildWeightCat === null) {
                cwc = 4;
            }
            cwc = Math.min(Math.max(cwc + val, 1), 7);
            this.setState({ 'CarouselState': cwc }, function () {
                this.setCarousel();
                //this.checkForm();
            });
        }
    }
    render() {
       
       //console.log( document.getElementById('aboutBtn'))
        
        return (
            <div className={`mapme-page ${this.state.AnimationState}`} style={{ visibility: this.state.visibility, overflow: 'hidden' }} id="mapme-SelectChild">
                <button type="button" tabIndex={this.state.locked ? -1 : null} className="mapme-desktop mapme-app-back-button" onClick={() => this.props.navigate("MapMeID", true)}>Back</button>
                <h2>{this.state.title}</h2>
                <Swipeable onSwiped={this.swipeHandler} onSwiping={this.swipingHandler} {...this.config}>
                    <div className={`mapme-select-child-carousel ${this.state.AnimationType}`}>
                        <div className={this.state.ChildWeightCat !== null ? 'mapme-selected' : ''}>
                            {
                                Array.from({ length: 7 }, (_, k) => (
                                    <div id={'mapme-carousel-child-' + (k + 1)} key={k} style={(k === 0 ? this.CarouselStyle : null)} className={(this.state.ChildWeightCat === (k + 1) || this.state.ChildWeightCat === null ? 'mapme-selected' : '') + ' ' + (this.state.CarouselState === (k + 1) ? 'mapme-carousel-active' : '')}>
                                        <img src={require('../' + this.props.getFileName(k + 1, 32)).default} draggable="false" alt={"Child of weight category " + (k + 1)} />
                                    </div>
                                ))
                            }
                            <button title={'expand child'} onClick={() => this.expand(this.state.CarouselState)}>{this.state.expandLabel}</button>
                        </div>
                    </div>
                </Swipeable>
                <div id="mapme-select-child-nav">
                    {
                        Array.from({ length: 7 }, (_, k) => (
                            <div key={k} className="mapme-child-select-radios">
                                <div className={'mapme-radio-image-selected ' + (this.state.ChildWeightCat === (k + 1) ? 'mapme-fadein' : 'mapme-fadeout')}>
                                    <img src={require('../' + this.props.getFileName(k + 1, 32)).default} draggable="false" alt={"Child of weight category " + (k + 1)} />
                                    <button title={'expand child ' + (k + 1)} className="mapme-expand-icon" onClick={() => this.expand(this.state.ChildWeightCat)}>Selected</button>
                                </div>
                                <label className="mapme-radio-image" aria-describedby={k + 1} htmlFor={'child-' + (k + 1)}>
                                    <img src={require('../' + this.props.getFileName(k + 1, 32)).default} draggable="false" alt={"Child of weight category " + (k + 1)} className={(this.state.ChildWeightCat !== null ? 'mapme-unselected' : '')} />
                                </label>
                                <input disabled={this.state.locked} id={'child-' + (k + 1)} checked={this.state.ChildWeightCat === (k + 1)} name="ChildWeightCat" onChange={this.radioEvent} type="radio" value={k + 1} />
                                <label htmlFor={'child-' + (k + 1)} className={this.state.CarouselState === (k + 1) ? 'mapme-carousel-active' : ''}>{k + 1}</label>
                            </div>
                        ))
                    }
                </div>
                <p><button type="button" tabIndex={this.state.locked ? -1 : null} className="mapme-app-button" onClick={() => this.props.navigate("EnterYourDetails", false)} disabled={this.state.disabled} >Select and continue</button></p>
               <details className="mapme-details" open=""><summary onClick={() => this.props.TrackPageHeight(100)}><div>What if my child looks different to this?</div></summary><span>{parse(this.props.data.text_areas.shape_explanation)}</span></details>
                
            </div>
        )
    }
}

export default SelectChild;