import React from 'react'
import { Component } from 'react'
import { Swipeable } from 'react-swipeable'


class ExpandChild extends Component {
    constructor(props) {
        super(props);
        this.setChild = this.setChild.bind(this);
        this.nav = this.nav.bind(this);
        this.getFileName = this.getFileName.bind(this);
        this.swipingHandler = this.swipingHandler.bind(this);
        this.swipeEndHandler = this.swipeEndHandler.bind(this);
        this.rotate = this.rotate.bind(this);
        this.setProgress = this.setProgress.bind(this);
        this.checkCancel = this.checkCancel.bind(this);
        this.setCarousel = this.setCarousel.bind(this);
        this.carousel_speed = 0.6;
        this.state = {
            Navigation: true,
            AnimationState: "",
            AnimationType: "",
            ChildWeightCat: null,
            Rotation: 0,
            Hint: 'mapme-fadein',
            h: 0,
            active: false
        };
        this.swiping = false;
        this.swipe_origin = null;
        this.total_images = 252
        this.images_loaded = 0
        this.progressStyle = {
            width: '0%'
        };
        this.preloadStyle = {
            display: 'block'
        };
        this.r_interval = null;
        this.r_val = 0;
        this.launch = this.launch.bind(this);
        this.die = this.die.bind(this);
        this.ExpandCarouselStyle = {
            marginLeft: '0'
        };
        this.config = {
            preventDefaultTouchmoveEvent: true,
            trackMouse: true
        };
    }

    setCarousel = () => {
        /*if (this.state.ChildWeightCat != null) {
            var c_offset = 100 - ((this.state.ChildWeightCat) * 100)
            this.ExpandCarouselStyle = {
                marginLeft: c_offset + '%',
                transition: 'margin-left ease ' + this.carousel_speed + 's'
            };
            this.forceUpdate();
        }*/
    }
    setChild(event) {
        if (this.props.getWidth() <= 40) {
            this.carousel_speed = 0.6
        } else {
            this.carousel_speed = Math.pow(Math.abs(event.target.value - this.state.ChildWeightCat) * 0.5, 0.8)
        }
        this.setState({ 'ChildWeightCat': parseInt(event.target.value) });
        this.props.SelectChild.current.setState({ ChildWeightCat: parseInt(event.target.value) , CarouselState: parseInt(event.target.value)}, this.props.SelectChild.current.setCarousel);
        this.props.EnterYourDetails.current.setState({ ChildWeightCat: parseInt(event.target.value) });
    }
    swipeEndHandler(e) {
        setTimeout(function () {
            this.swiping = false;
        }.bind(this), 100)

        this.swipe_origin = null;
        this.start_frame = this.state.Rotation;
    }
    swipingHandler(e) {
        if (!this.swiping) {
            this.swiping = true;
            this.swipe_origin = e.deltaX;
            this.start_frame = this.state.Rotation;
        } else {
            var dist = (this.swipe_origin - e.deltaX) / window.innerWidth;
            var mult = 20
            if (window.innerWidth > 640) {
                mult = 40
            }
            var r = this.start_frame + Math.round(dist * mult)
            if (r < 0) {
                r += 35
            }
            if (r > 35) {
                r -= 35
            }
            this.setState({ Rotation: r, Hint: 'mapme-fadeout' })
        }
    }

    getFileName(k, j) {
        var file = this.props.getFileName(k, j)
        if (!this.state.active) {
            file = ''
        }
        return file;
    }
    nav(val) {
        console.log('nav '+val)
        this.rotate(0);
        if (Math.abs(val) === 1) {
            this.carousel_speed = 0.6;
        }
        if (this.state.active) {
            var cwc = Math.min(Math.max(this.state.ChildWeightCat + val, 1), 7);
            this.setState({ 'ChildWeightCat': cwc });
            this.props.SelectChild.current.setState({ ChildWeightCat: cwc , CarouselState:cwc}, this.props.SelectChild.current.setCarousel);
            this.props.EnterYourDetails.current.setState({ ChildWeightCat: parseInt(cwc) });
        }
    }
    checkCancel(e) {
        if (e.target.getAttribute('class') != null && !this.swiping) {
            if (e.target.getAttribute('class').indexOf('mapme-expand-child') > -1) {
                this.die();
            }
        }
    }
    setProgress() {
        this.images_loaded++
        if (this.images_loaded === this.total_images && this.images_loaded > 0) {
            setTimeout(function () {
                this.preloadStyle = {
                    display: 'none'
                };
                this.forceUpdate();
            }.bind(this), 600)
        }
        this.progressStyle = {
            width: ((this.images_loaded / this.total_images) * 100) + '%'
        };
        this.forceUpdate();


    }
    rotate(val,event) {
        var status = true;
        if(event!==undefined && val !== 0){
            status = false;
            if(event.keyCode === 32 || event.keyCode === 13){
                status = true;
            }
        };
        if(status){
            clearInterval(this.r_interval);
            this.r_val = val;
            if (val !== 0) {
                console.log('rotation started')
                this.r_interval = setInterval(function () {
                    var r = this.state.Rotation + this.r_val;
                    if (r < 0) {
                        r = 35
                    }
                    if (r > 35) {
                        r = 0
                    }
                    this.setState({ 'Hint': 'mapme-fadeout', 'Rotation': r });
                }.bind(this), 30)
            }
        }
    }
    launch(val, nav) {
        this.images_loaded = 0;
        this.progressStyle = {
            width: '0%'
        };
        this.preloadStyle = {
            display: 'block'
        };

        this.setState({
            Navigation: nav,
            ChildWeightCat: val,
            AnimationState: "mapme-fadein",
            AnimationType: "mapme-snap",
            Rotation: 0,
            Hint:"mapme-fadein"
        });

        this.props.SelectChild.current.setState({ AnimationType: "mapme-snap" });
        setTimeout(() => {
            this.setState({ AnimationType: "" });
            this.props.lockRoot(true);
            if(this.props.keynav){
                document.querySelector('.mapme-expand-child .mapme-close').focus()
             }
        }, 300);
        this.forceUpdate();
    }
    die() {
        this.images_loaded = 0;
        this.setState({
            AnimationState: "mapme-fadeout"
        });
        setTimeout(() => {
            this.setState({ AnimationState: "" });
            this.props.SelectChild.current.setState({ AnimationType: "" });
            this.props.lockRoot(false);
            this.props.TrackPageHeight(300);
        }, 600);
    }
    render() {
        return (
            <Swipeable onSwiping={this.swipingHandler} {...this.config} onSwiped={this.swipeEndHandler} {...this.config}>
                <div className={`mapme-expand-child ${this.state.AnimationState}`} onMouseUp={() => this.rotate(0)} onKeyUp={() => this.rotate(0)} onClick={(e) => this.checkCancel(e)}>
                    <div>
                        <div id="mapme-expand-child-carousel" className={`mapme-expand-child-carousel mapme-rotate-view ${this.state.AnimationType}`} ><div className={!this.state.Navigation ? 'mapme-no-nav' : ''}>
                            {
                                Array.from({ length: 7 }, (_, k) => (
                                    <div style = {{zIndex:(k+1)}} id={'mapme-carousel-expand-child-' + (k + 1)} key={k} className={((k + 1) === this.state.ChildWeightCat ? 'mapme-carousel-selected mapme-fadein' : 'mapme-fadeout')} >
                                        {Array.from({ length: 36 }, (_, j) => (
                                            <div key={j}>
                                                <img src={require('../' + this.getFileName(k + 1, j)).default} draggable="false"  onLoad={(e) => this.setProgress(e)} alt={"Child of weight category " + (k + 1)} className={(j === this.state.Rotation ? 'mapme-rotateIn' : 'mapme-rotateOut')} />
                                            </div>
                                        ))}
                                    </div>
                                ))
                            }
                        </div><div className={`mapme-touchicon ${this.state.Hint}`}><span className="mapme-desktop">Drag or use<br/>arrows to rotate</span><span className="mapme-mobile">Drag to rotate</span></div></div>
                        <div className="mapme-desktop mapme-carousel-fade mapme-left"></div>
                        <div className="mapme-desktop mapme-carousel-fade mapme-right"></div>
                        <button onMouseDown={() => this.rotate(-1)}  onKeyDown={(event) => this.rotate(-1,event)} className="mapme-left-arrow mapme-desktop" title="Rotate left"></button>
                        <button onMouseDown={() => this.rotate(1)} onKeyDown={(event) => this.rotate(1,event)}   className="mapme-right-arrow mapme-desktop" title="Rotate right"></button>
                        <div className="mapme-expand-child-nav mapme-desktop" style={{ display: this.state.Navigation ? 'block' : 'none' }}>
                            {
                                Array.from({ length: 7 }, (_, k) => (
                                    <div key={k} className="mapme-child-select-radios">
                                        <input id={'child-expanded-' + (k + 1)} checked={this.state.ChildWeightCat === (k + 1)} name="ChildWeightCatExpanded" type="radio" onChange={this.setChild} value={k + 1} />
                                        <label htmlFor={'child-expanded-' + (k + 1)}>{k + 1}</label>
                                    </div>
                                ))
                            }
                            <span className="mapme-expand-child-nav-label">Select body shape</span>
                        </div>
                        <button onClick={this.die} className="mapme-close" title="close">Close</button>
                    </div>
                    <div style={this.preloadStyle} className={`mapme-preload ${((this.total_images === this.images_loaded) && (this.total_images > 0) ? 'mapme-fadeout' : '')}`}>
                        <div><div style={this.progressStyle}></div></div>
                        <div className="mapme-preload-label">Loading</div>
                    </div>
                </div>

            </Swipeable>

        )
    }
}
export default ExpandChild;