import React from 'react'
import { Component } from 'react'
class PageWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            h: 0,
            animated: false
        };
    }
    componentDidMount() {
        this.setState({
            h: document.getElementById('mapme-Start').style.height,
            preload:'mapme-fadeout'
        })
    }
    render() {
        return (
            <div className={'mapme-pages ' + (this.state.animated === true ? 'animated' : '')} id="mapme-pages" style={{ height: this.state.h + 'px' }} >
                {this.props.children}
                <div id="mapme-preloader" className = {this.state.preload}>
                    <div id="mapme-preload-animation">
                        <div>
                            <span><div></div><div></div></span>
                            <span><div></div><div></div></span>
                        </div>
                    </div>
                <span>So, do you see your child's weight&nbsp;clearly?</span>
            </div>
            </div >

        )
    }
}

export default PageWrapper;