import React from 'react'
import {Component} from 'react' 
import {
    isMobile,
    isMobileSafari
} from "react-device-detect";
class AppWrapper extends Component {   
    constructor(props) {
        super(props);
        this.genders = ['M','F'];
        this.AgeGroups = ['REC','YR6'];
        this.setProgress =  this.setProgress.bind(this);
        this.images_loaded = 0;
        this.total_images = 0;
        this.state = {
            ExpandStyle:"",
            KeynavStyle:"",
            FooterStyle:"mapme-fadein"
        };
        this.progressStyle = {
            width: '0%'
        };
        this.preloadStyle = {
            display:'block'
        };
    }
    componentDidMount() {
        for(var i in this.genders){
            for(var j in this.AgeGroups){
                for(var k= 1;k<=7;k++){
                    this.total_images++;
                    var filename = this.genders[i]+'-'+this.AgeGroups[j]+'-'+k+'-1.'+this.props.imgtype
                    let img = new Image();
                    img.src = require('../assets/3d/'+filename).default
                    img.onload = e => this.setProgress()
                }
            }
        }
       /* const btns = document.querySelectorAll("button summary details a");
        btns.forEach(function(item) {
           item.addEventListener("click", function(e){
               setTimeout(function(){
                   e.target.blur();
               },500)
           }); 
       })*/
    }
    setProgress(){
        this.images_loaded++
        if(this.images_loaded === this.total_images && this.total_images> 0){
            setTimeout(function(){
                this.preloadStyle = {
                    display:'none'
                };
                this.props.setPreloadState()
                this.forceUpdate();
            }.bind(this),600)
        }
        this.progressStyle = {
            width: ((this.images_loaded / this.total_images) * 100)+'%'
        };
        this.forceUpdate();
    }
    render(){
        return(
            <div id = "mapme-app" className={`mapme-app ${this.state.ExpandStyle} ${this.state.KeynavStyle} ${this.state.FooterStyle} ${isMobileSafari ? 'mapme-mobile-safari' : ''}  ${isMobile ? 'mapme-touch' : ''}`}>
                <div style={this.preloadStyle} className={`mapme-preload ${((this.total_images === this.images_loaded) && (this.total_images > 0) ? 'mapme-fadeout' : '')}`}>
                    <div><div style={this.progressStyle}></div></div>
                </div>
                {this.props.children}
            </div>
        )
    }
}
export default AppWrapper;