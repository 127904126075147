import React from 'react'
import {Component} from 'react' 
import parse from 'html-react-parser';
class Start extends Component {   
    constructor(props) {
        super(props);
        this.state = {
            AnimationState:"mapme-fadein-delayed",
            visibility:"visible",
            tabIndex:null,
            locked:false,
            page_id:0
        };
        window.history.pushState(null,null,'Start')
    }
    reset(){}
    render(){
        return(
            <div className={`mapme-page ${this.state.AnimationState}`} style={{visibility:this.state.visibility}} id="mapme-Start">
                <h1>MapMe</h1>
                <div>{parse(this.props.data.text_areas.splash_copy)}</div>
                <h2>{this.props.data.text_areas.splash_prompt}</h2>
                <button type="button" tabIndex = {this.state.tabIndex} className="mapme-app-button" id="mapme-start-button" onClick={() => this.props.navigate("Form1",false)}>{this.props.data.text_areas.start_btn}</button>
                <p><a href="https://nhs.uk" tabIndex={this.state.locked ? -1 : null} id = "mapme_nhs_credit"  title = "In partnership with the NHS">In partnership with</a></p>
                <div className = "mapme-disclaimer">{parse(this.props.data.text_areas.splash_disclaimer)}</div>
            </div>
        )
    }
}

export default Start;